import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faGears, 
    faPalette, 
    faMapMarkerAlt, 
    faEnvelope, 
    faPhone,
    faClock,
    faMoneyBillWave,
    faCity,
    faNoteSticky,
    faShare,
    faExclamationCircle,
    faUser,
    faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { Spinner, Card, Container, Row, Col, Badge, Button } from 'react-bootstrap';

const BuyDetail = () => {
    const { id } = useParams();
    const [carData, setCarData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getCarLogo = (make) => {
        const logoMap = {
            'suzuki': '/assets/carslogos/suzuki.png',
            'toyota': '/assets/carslogos/toyota.png',
            'honda': '/assets/carslogos/honda.png',
            'kia': '/assets/carslogos/kia.png',
            'nissan': '/assets/carslogos/nissan.png',
            'hyundai': '/assets/carslogos/hyundai.png',
            'bmw': '/assets/carslogos/bmw.png',
            'mercedes': '/assets/carslogos/mercedes.png',
            'audi': '/assets/carslogos/audi.png'
        };
        
        const makeLower = make.toLowerCase();
        return logoMap[makeLower] || '/assets/carslogos/default.png';
    };

    const getData = async () => {
        try {
            const res = await axios.get(`https://api.aboutcars.pk/api/cars/all-buy`);
            if (res.data && res.data.Status === "Success") {
                const buyRequest = res.data.data.find(request => request._id === id);
                setCarData(buyRequest);
            } else {
                setError("Failed to fetch buy request details");
            }
        } catch (error) {
            setError(error.message || "An error occurred while fetching data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleCloseCar = async () => {
        try {
            await axios.put(`https://api.aboutcars.pk/api/cars/update-car-buy?id=${id}`);
            getData(); // Refresh data after update
        } catch (error) {
            console.error('Error closing request:', error);
        }
    };

    if (loading) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" variant="danger" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger m-4" role="alert">
                {error}
            </div>
        );
    }

    if (!carData) {
        return (
            <div className="alert alert-info m-4" role="alert">
                No data found for this buy request
            </div>
        );
    }

    const formatPrice = (price) => {
        return Number(price).toLocaleString('en-PK', {
            style: 'currency',
            currency: 'PKR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    return (
        <div className="buy-details-page">
            <Container fluid className="px-4">
                <div className="back-section mb-4 d-flex justify-content-between align-items-center">
                    <Link to="/carsDashboard" className="back-link">
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Dashboard
                    </Link>
                </div>
                <Card className="buy-detail-card">
                    <Card.Body>
                        <Row>
                            <Col lg={8}>
                                <div className="car-header">
                                    <div className="car-title-section">
                                        <div className="car-logo-container">
                                            <img 
                                                src={getCarLogo(carData.make.make)}
                                                alt={carData.make.make}
                                                className="car-logo"
                                            />
                                        </div>
                                        <div>
                                            <h2>{carData.make.make} {carData.make.model} {carData.make.version}</h2>
                                        </div>
                                    </div>
                                    <div className="budget-badge">
                                        <FontAwesomeIcon icon={faMoneyBillWave} />
                                        Budget: {formatPrice(carData.minBudget)} - {formatPrice(carData.maxBudget)}
                                    </div>
                                </div>

                                <div className="year-range mb-4">
                                    <p className="text-muted mb-0">Year Range: {carData.from} - {carData.to}</p>
                                </div>
                                
                                <div className="specifications">
                                    <Row>
                                        <Col md={4}>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faGears} className="spec-icon" />
                                                <div>
                                                    <h6>Transmission</h6>
                                                    <p>{carData.transmission.join(", ")}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faPalette} className="spec-icon" />
                                                <div>
                                                    <h6>Color</h6>
                                                    <p>{carData.exteriorColor.join(", ")}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faCity} className="spec-icon" />
                                                <div>
                                                    <h6>Registration City</h6>
                                                    <p>{carData.registerCity.join(", ")}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {carData.notes && (
                                    <div className="notes-section mt-4">
                                        <h5>
                                            <FontAwesomeIcon icon={faNoteSticky} className="me-2" />
                                            Additional Notes
                                        </h5>
                                        <p>{carData.notes}</p>
                                    </div>
                                )}

                                <div className="urgency-section mt-4">
                                    <h5>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                                        Urgency Level
                                    </h5>
                                    <Badge bg={carData.urgency.includes('High') ? 'danger' : 'warning'}>
                                        {carData.urgency}
                                    </Badge>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <Card className="contact-card">
                                    <Card.Body>
                                        <h4 className="mb-4">Contact Information</h4>
                                        
                                        <div className="contact-info">
                                            <div className="info-item">
                                                <FontAwesomeIcon icon={faUser} className="info-icon" />
                                                <div>
                                                    <h6>Name</h6>
                                                    <p>{carData.name}</p>
                                                </div>
                                            </div>

                                            <div className="info-item">
                                                <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
                                                <div>
                                                    <h6>Email</h6>
                                                    <p>{carData.email}</p>
                                                </div>
                                            </div>

                                            <div className="info-item">
                                                <FontAwesomeIcon icon={faPhone} className="info-icon" />
                                                <div>
                                                    <h6>Phone</h6>
                                                    <p>+{carData.contactNo}</p>
                                                </div>
                                            </div>

                                            <div className="info-item">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} className="info-icon" />
                                                <div>
                                                    <h6>Location</h6>
                                                    <p>{carData.address}, {carData.city}</p>
                                                </div>
                                            </div>

                                            <div className="info-item">
                                                <FontAwesomeIcon icon={faClock} className="info-icon" />
                                                <div>
                                                    <h6>Posted On</h6>
                                                    <p>{new Date(carData.date).toLocaleDateString()}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <Button 
                                                variant={carData.active ? "danger" : "secondary"}
                                                className="w-100"
                                                onClick={handleCloseCar} 
                                            >
                                                {carData.active ? "Close Request" : "Request Closed"}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            
            <style jsx>{`
                .buy-detail-card {
                    border: none;
                    box-shadow: 0 0 20px rgba(0,0,0,0.08);
                    border-radius: 12px;
                    background: #fff;
                }

                .car-header {
                    padding: 2rem;
                    background: #f8f9fa;
                    border-radius: 12px 12px 0 0;
                }

                .car-logo-container {
                    width: 73px;
                    height: 61px;
                    display: flex;
                    margin-right: 12px;
                }

                .car-logo {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }

                .car-title-section {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.5rem;
                }

                .car-title-section h2 {
                    font-size: 1.75rem;
                    font-weight: 600;
                    margin: 0;
                    color: #333;
                }

                .budget-badge {
                    display: inline-flex;
                    align-items: center;
                    background: #ed1c24;
                    color: white;
                    padding: 0.75rem 1.25rem;
                    border-radius: 25px;
                    font-weight: 500;
                    font-size: 1rem;
                }

                .budget-badge svg {
                    margin-right: 8px;
                }

                .specifications {
                    background: #fff;
                    padding: 1.5rem;
                    border-radius: 8px;
                    margin-top: 1rem;
                }

                .spec-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    margin-bottom: 1rem;
                }

                .spec-icon {
                    color: #ed1c24;
                    font-size: 1.2rem;
                }

                .spec-item h6 {
                    margin: 0;
                    color: #666;
                    font-size: 0.9rem;
                }

                .spec-item p {
                    margin: 0;
                    font-weight: 600;
                    color: #333;
                }

                .notes-section {
                    background: #fff;
                    padding: 1.5rem;
                    border-radius: 8px;
                    border: 1px solid #eee;
                }

                .contact-card {
                    background: #f8f9fa;
                    border: none;
                    border-radius: 8px;
                }

                .info-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    margin-bottom: 1.5rem;
                }

                .info-icon {
                    color: #ed1c24;
                    font-size: 1.2rem;
                }

                .info-item h6 {
                    margin: 0;
                    color: #666;
                    font-size: 0.9rem;
                }

                .info-item p {
                    margin: 0;
                    font-weight: 600;
                    color: #333;
                }

                .urgency-section {
                    background: #fff;
                    padding: 1.5rem;
                    border-radius: 8px;
                    border: 1px solid #eee;
                }

                @media (max-width: 768px) {
                    .container-fluid {
                        padding: 0.5rem;
                    }
                    
                    .car-header {
                        text-align: center;
                    }
                    
                    .budget-badge {
                        display: block;
                        margin: 1rem auto;
                    }
                    
                    .contact-card {
                        margin-top: 2rem;
                    }
                }

                .back-link {
                    color: #ed1c24;
                    text-decoration: none;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    gap: 0.75rem;
                    font-size: 1.1rem;
                    transition: all 0.3s ease;
                }

                .back-link:hover {
                    color: #d91414;
                    transform: translateX(-5px);
                }
            `}</style>
        </div>
    );
};

export default BuyDetail;