import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCar,
    faCalendarAlt,
    faTachometerAlt,
    faCog,
    faGasPump,
    faPalette,
    faMoneyBillWave,
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { API_ENDPOINT } from '../../config/config';

const CarDetails = () => {
    const { id } = useParams();
    const [car, setCar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeImage, setActiveImage] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const getCarLogo = (make) => {
        const logoMap = {
            'suzuki': '/assets/carslogos/suzuki.png',
            'toyota': '/assets/carslogos/toyota.png',
            'honda': '/assets/carslogos/honda.png',
            'kia': '/assets/carslogos/kia.png',
            'nissan': '/assets/carslogos/nissan.png',
            'hyundai': '/assets/carslogos/hyundai.png',
            'bmw': '/assets/carslogos/bmw.png',
            'mercedes': '/assets/carslogos/mercedes.png',
            'audi': '/assets/carslogos/audi.png'
        };
        
        const makeLower = make.toLowerCase();
        return logoMap[makeLower] || '/assets/carslogos/default.png';
    };

    const capitalizeVersion = (version) => {
        if (!version) return 'Standard Edition';
        return version.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const capitalizeFirst = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    useEffect(() => {
        const fetchCarDetails = async () => {
            try {
                const response = await axios.get(`https://api.aboutcars.pk/api/stock/cars/${id}`);
                if (response.data && response.data.success) {
                    setCar(response.data.data);
                }
            } catch (err) {
                setError(err.message || 'Failed to fetch car details');
            } finally {
                setLoading(false);
            }
        };

        fetchCarDetails();
    }, [id]);

    const handleNextImage = () => {
        if (car.images.length > 0) {
            setActiveImage((prev) => (prev + 1) % car.images.length);
        }
    };

    const handlePrevImage = () => {
        if (car.images.length > 0) {
            setActiveImage((prev) => (prev - 1 + car.images.length) % car.images.length);
        }
    };

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'ArrowLeft') {
                handlePrevImage();
            } else if (e.key === 'ArrowRight') {
                handleNextImage();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [car]);

    // Handle touch events for swipe
    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;

        if (isLeftSwipe) {
            handleNextImage();
        } else if (isRightSwipe) {
            handlePrevImage();
        }

        setTouchStart(0);
        setTouchEnd(0);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <Spinner animation="border" variant="danger" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <div className="alert alert-danger">{error}</div>
            </div>
        );
    }

    if (!car) {
        return (
            <div className="not-found-container">
                <h2>Car not found</h2>
                <Link to="/stock" className="back-link">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Stock
                </Link>
            </div>
        );
    }

    return (
        <div className="car-details-page">
            <Container fluid className="px-4">
                <div className="back-section mb-4">
                    <Link to="/stock" className="back-link">
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Stock
                    </Link>
                </div>

                <Row className="gx-4">
                    <Col lg={8}>
                        <div className="image-gallery">
                            <div 
                                className="main-image"
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                <img
                                    src={`http://api.aboutcars.pk${car.images[activeImage]}`}
                                    alt={`${car.make} ${car.model}`}
                                />
                                <div className="image-navigation">
                                    <button 
                                        className="nav-button prev"
                                        onClick={handlePrevImage}
                                        aria-label="Previous image"
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <button 
                                        className="nav-button next"
                                        onClick={handleNextImage}
                                        aria-label="Next image"
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </div>
                                <div className="image-counter">
                                    {activeImage + 1} / {car.images.length}
                                </div>
                            </div>
                            <div className="thumbnail-container">
                                {car.images.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`thumbnail ${index === activeImage ? 'active' : ''}`}
                                        onClick={() => setActiveImage(index)}
                                    >
                                        <img
                                            src={`http://api.aboutcars.pk${image}`}
                                            alt={`${car.make} ${car.model} - View ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col lg={4}>
                        <Card className="car-info-card">
                            <Card.Body className="p-4">
                                <div className="car-header">
                                    <div className="car-title-section">
                                        <h1 className="car-title">{car.make} {car.model}</h1>
                                        <div className="car-version">
                                            <FontAwesomeIcon icon={faCar} className="version-icon" />
                                            {capitalizeVersion(car.version)}
                                        </div>
                                    </div>
                                    <div className="company-logo">
                                        <img src={getCarLogo(car.make)} alt={car.make} />
                                    </div>
                                </div>
                                
                                <div className="price-section">
                                    <FontAwesomeIcon icon={faMoneyBillWave} className="price-icon" />
                                    <span className="currency">PKR</span>
                                    <span className="amount">{car.price ? car.price.toLocaleString() : '0'}</span>
                                </div>

                                <div className="specs-grid">
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className="spec-details">
                                            <span className="label">Year</span>
                                            <span className="value">{car.year}</span>
                                        </div>
                                    </div>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faTachometerAlt} />
                                        <div className="spec-details">
                                            <span className="label">Mileage</span>
                                            <span className="value">{car.mileage ? car.mileage.toLocaleString() : '0'} km</span>
                                        </div>
                                    </div>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faGasPump} />
                                        <div className="spec-details">
                                            <span className="label">Fuel Type</span>
                                            <span className="value">{capitalizeFirst(car.fuel_type)}</span>
                                        </div>
                                    </div>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faCog} />
                                        <div className="spec-details">
                                            <span className="label">Transmission</span>
                                            <span className="value">{capitalizeFirst(car.transmission)}</span>
                                        </div>
                                    </div>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faPalette} />
                                        <div className="spec-details">
                                            <span className="label">Color</span>
                                            <span className="value">{capitalizeFirst(car.color)}</span>
                                        </div>
                                    </div>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        <div className="spec-details">
                                            <span className="label">Registration City</span>
                                            <span className="value">{capitalizeFirst(car.registration_city)}</span>
                                        </div>
                                    </div>
                                </div>

                                {car.description && (
                                    <div className="description-section">
                                        <h3>Description</h3>
                                        <p>{car.description}</p>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <style jsx>{`
                .car-details-page {
                    background-color: #f4f6f8;
                    min-height: 100vh;
                    padding: 2.5rem 0;
                }

                .back-section {
                    margin-top: -1rem;
                    margin-bottom: 2rem;
                }

                .back-link {
                    color: #dc3545;
                    text-decoration: none;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    gap: 0.75rem;
                    font-size: 1.1rem;
                    transition: all 0.3s ease;
                }

                .back-link:hover {
                    color: #c82333;
                    transform: translateX(-5px);
                }

                .image-gallery {
                    background: white;
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
                }

                .main-image {
                    width: 100%;
                    height: 550px;
                    position: relative;
                    overflow: hidden;
                    cursor: grab;
                }

                .main-image:active {
                    cursor: grabbing;
                }

                .main-image img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease;
                }

                .main-image:hover img {
                    transform: scale(1.02);
                }

                .image-navigation {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 1rem;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                .main-image:hover .image-navigation {
                    opacity: 1;
                }

                .nav-button {
                    background: rgba(220, 53, 69, 0.8);
                    color: white;
                    border: none;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    z-index: 2;
                }

                .nav-button:hover {
                    background: rgba(220, 53, 69, 1);
                    transform: scale(1.1);
                }

                .image-counter {
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                    background: rgba(0, 0, 0, 0.6);
                    color: white;
                    padding: 0.5rem 1rem;
                    border-radius: 20px;
                    font-size: 0.9rem;
                    z-index: 2;
                }

                .thumbnail-container {
                    display: flex;
                    gap: 0.75rem;
                    padding: 1.25rem;
                    background: white;
                    overflow-x: auto;
                }

                .thumbnail {
                    width: 120px;
                    height: 90px;
                    flex-shrink: 0;
                    cursor: pointer;
                    border-radius: 8px;
                    overflow: hidden;
                    opacity: 0.7;
                    transition: all 0.3s ease;
                    border: 2px solid transparent;
                }

                .thumbnail.active {
                    opacity: 1;
                    border: 2px solid #dc3545;
                }

                .thumbnail:hover {
                    opacity: 0.9;
                    transform: translateY(-2px);
                }

                .thumbnail img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .car-info-card {
                    border: none;
                    border-radius: 15px;
                    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
                    height: 100%;
                }

                .car-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1.5rem;
                }

                .car-title-section {
                    flex: 1;
                }

                .car-title {
                    font-size: 2.25rem;
                    font-weight: 700;
                    color: #dc3545;
                    margin-bottom: 0.5rem;
                    line-height: 1.2;
                }

                .car-version {
                    font-size: 1.1rem;
                    color: #666;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    margin-top: 0.5rem;
                }

                .version-icon {
                    color: #dc3545;
                    font-size: 1rem;
                }

                .company-logo {
                    width: 80px;
                    height: 80px;
                    margin-left: 1.5rem;
                    padding: 0.5rem;
                    background: #fff;
                    border-radius: 12px;
                    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .company-logo img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .price-section {
                    background: #ed1c24;
                    padding: 1.5rem;
                    border-radius: 12px;
                    margin-bottom: 2rem;
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;
                    box-shadow: 0 4px 12px rgba(220, 53, 69, 0.2);
                }

                .price-icon {
                    color: white;
                    font-size: 1.75rem;
                }

                .currency {
                    color: rgba(255, 255, 255, 0.9);
                    font-size: 1.3rem;
                }

                .amount {
                    font-size: 2.25rem;
                    font-weight: 700;
                    color: white;
                }

                .specs-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2rem;
                    margin-bottom: 2.5rem;
                }

                .spec-item {
                    display: flex;
                    align-items: center;
                    gap: 1.25rem;
                }

                .spec-item svg {
                    color: #dc3545;
                    font-size: 1.5rem;
                }

                .spec-details {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                }

                .label {
                    font-size: 0.9rem;
                    color: #666;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                }

                .value {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: #dc3545;
                }

                .description-section {
                    border-top: 1px solid #eee;
                    padding-top: 2rem;
                    margin-top: 2rem;
                }

                .description-section h3 {
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: #dc3545;
                    margin-bottom: 1.25rem;
                }

                .description-section p {
                    color: #444;
                    line-height: 1.8;
                    font-size: 1.1rem;
                }

                .loading-container,
                .error-container,
                .not-found-container {
                    min-height: 70vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 1.5rem;
                }

                @media (max-width: 992px) {
                    .main-image {
                        height: 450px;
                    }

                    .car-info-card {
                        margin-top: 2rem;
                    }

                    .car-title {
                        font-size: 2rem;
                    }
                }

                @media (max-width: 768px) {
                    .container-fluid {
                        padding-left: 1.25rem;
                        padding-right: 1.25rem;
                    }

                    .main-image {
                        height: 350px;
                    }

                    .specs-grid {
                        grid-template-columns: 1fr;
                        gap: 1.5rem;
                    }

                    .car-header {
                        flex-direction: column-reverse;
                        align-items: center;
                        text-align: center;
                        gap: 1rem;
                    }

                    .company-logo {
                        margin-left: 0;
                        margin-bottom: 1rem;
                    }

                    .car-title {
                        font-size: 1.75rem;
                    }

                    .car-version {
                        justify-content: center;
                        font-size: 1rem;
                    }

                    .amount {
                        font-size: 1.75rem;
                    }

                    .thumbnail {
                        width: 100px;
                        height: 75px;
                    }

                    .image-navigation {
                        opacity: 1;
                    }

                    .nav-button {
                        width: 36px;
                        height: 36px;
                    }
                }
            `}</style>
        </div>
    );
};

export default CarDetails; 