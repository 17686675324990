import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT } from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCar, 
    faGears, 
    faPalette, 
    faMapMarkerAlt, 
    faEnvelope, 
    faPhone,
    faClock,
    faMoneyBillWave,
    faCity,
    faTachometerAlt,
    faCalendarAlt,
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faHeart
} from '@fortawesome/free-solid-svg-icons';
import { Spinner, Card, Container, Row, Col, Badge, Button } from 'react-bootstrap';

const SalesDetails = () => {
    const { id } = useParams();
    const [carData, setCarData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeImage, setActiveImage] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoriteLoading, setFavoriteLoading] = useState(false);

    const getCarLogo = (make) => {
        const logoMap = {
            'suzuki': '/assets/carslogos/suzuki.png',
            'toyota': '/assets/carslogos/toyota.png',
            'honda': '/assets/carslogos/honda.png',
            'kia': '/assets/carslogos/kia.png',
            'nissan': '/assets/carslogos/nissan.png',
            'hyundai': '/assets/carslogos/hyundai.png',
            'bmw': '/assets/carslogos/bmw.png',
            'mercedes': '/assets/carslogos/mercedes.png',
            'audi': '/assets/carslogos/audi.png'
        };
        
        const makeLower = make.toLowerCase();
        return logoMap[makeLower] || '/assets/carslogos/default.png';
    };

    const checkFavoriteStatus = async () => {
        try {
            const response = await axios.get(`${API_ENDPOINT}/admin/all-favorite-sell`);
            const favorites = response.data.data.favSellCarsArray;
            setIsFavorite(favorites.some(car => car._id === id));
        } catch (error) {
            console.error('Error checking favorite status:', error);
        }
    };

    const handleToggleFavorite = async () => {
        try {
            setFavoriteLoading(true);
            if (!isFavorite) {
                await axios.post(`${API_ENDPOINT}/admin/favorite-sell`, {
                    sellId: id
                });
                setIsFavorite(true);
            } else {
                // You'll need to add an endpoint to remove from favorites
                await axios.delete(`${API_ENDPOINT}/admin/favorite-sell/${id}`);
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error toggling favorite:', error);
        } finally {
            setFavoriteLoading(false);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${API_ENDPOINT}/cars/single-sell?id=${id}`);
            if (res.data && res.data.data) {
                setCarData(res.data.data);
                await checkFavoriteStatus();
            } else {
                setError("Failed to fetch car details");
            }
        } catch (error) {
            setError(error.message || "An error occurred while fetching data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleCloseCar = async () => {
        try {
            await axios.put(`${API_ENDPOINT}/cars/update-car-sell?id=${id}`);
            getData();
        } catch (error) {
            console.error('Error closing car:', error);
        }
    };

    const handleNextImage = () => {
        if (carData.carImages.length > 0) {
            setActiveImage((prev) => (prev + 1) % carData.carImages.length);
        }
    };

    const handlePrevImage = () => {
        if (carData.carImages.length > 0) {
            setActiveImage((prev) => (prev - 1 + carData.carImages.length) % carData.carImages.length);
        }
    };

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'ArrowLeft') {
                handlePrevImage();
            } else if (e.key === 'ArrowRight') {
                handleNextImage();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [carData]);
    
    // Handle touch events for swipe
    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;

        if (isLeftSwipe) {
            handleNextImage();
        } else if (isRightSwipe) {
            handlePrevImage();
        }

        setTouchStart(0);
        setTouchEnd(0);
    };

    // Update the getImageUrl function
    const getImageUrl = (imagePath) => {
        if (!imagePath) return '/assets/images/no-image.png';
        
        // If the path is already a full URL, return it as is
        if (imagePath.startsWith('http')) {
            return imagePath;
        }

        // Get the base URL without the /api suffix
        const baseUrl = API_ENDPOINT.replace('/api', '');
        
        // If the path starts with 'uploads/', remove it as it's already included in the server path
        const cleanPath = imagePath.startsWith('uploads/') ? imagePath : `uploads/${imagePath}`;
        
        // Construct the full URL
        return `${baseUrl}/${cleanPath}`;
    };

    if (loading) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" variant="danger" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger m-4" role="alert">
                {error}
            </div>
        );
    }

    if (!carData) {
        return (
            <div className="alert alert-info m-4" role="alert">
                No data found for this car
            </div>
        );
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="car-details-page">
            <Container fluid className="px-4">
                <div className="back-section mb-4 d-flex justify-content-between align-items-center">
                    <Link to="/carsDashboard" className="back-link">
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Dashboard
                    </Link>
                    <button 
                        className={`favorite-button ${isFavorite ? 'is-favorite' : ''}`}
                        onClick={handleToggleFavorite}
                        disabled={favoriteLoading}
                    >
                        <FontAwesomeIcon 
                            icon={faHeart}
                            className={isFavorite ? 'text-white' : ''}
                        />
                        <span className="ms-2">
                            {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                        </span>
                    </button>
                </div>

                <Row className="gx-4">
                    <Col lg={8}>
                        <div className="image-gallery">
                            <div 
                                className="main-image"
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                <img
                                    src={getImageUrl(carData.carImages[activeImage])}
                                    alt={`${carData.make.make} ${carData.make.model}`}
                                />
                                <div className="image-navigation">
                                    <button 
                                        className="nav-button prev"
                                        onClick={handlePrevImage}
                                        aria-label="Previous image"
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <button 
                                        className="nav-button next"
                                        onClick={handleNextImage}
                                        aria-label="Next image"
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </div>
                                <div className="image-counter">
                                    {activeImage + 1} / {carData.carImages.length}
                                </div>
                            </div>
                            <div className="thumbnail-container">
                                {carData.carImages.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`thumbnail ${index === activeImage ? 'active' : ''}`}
                                        onClick={() => setActiveImage(index)}
                                    >
                                        <img
                                            src={getImageUrl(image)}
                                            alt={`${carData.make.make} ${carData.make.model} - View ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="specifications mt-4">
                            <Row>
                                <Col md={4}>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faTachometerAlt} className="spec-icon" />
                                        <div>
                                            <h6>Mileage</h6>
                                            <p>{carData.milage} km</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faGears} className="spec-icon" />
                                        <div>
                                            <h6>Transmission</h6>
                                            <p>{carData.transmission}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="spec-item">
                                        <FontAwesomeIcon icon={faPalette} className="spec-icon" />
                                        <div>
                                            <h6>Color</h6>
                                            <p>{carData.exteriorColor}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {carData.notes && (
                            <div className="notes-section mt-4">
                                <h5>Additional Notes</h5>
                                <p>{carData.notes}</p>
                            </div>
                        )}

                        <div className="specifications mt-4">
                            <h5 className="section-title">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                                Availability
                            </h5>
                            <div className="availability-details">
                                {Array.isArray(carData.availability) ? (
                                    carData.availability.map((item, index) => (
                                        <div key={index} className="availability-item">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="spec-icon" />
                                            <div>
                                                <h6>Location</h6>
                                                <p>{item}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="availability-item">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="spec-icon" />
                                        <div>
                                            <h6>Location</h6>
                                            <p>{carData.availability}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col lg={4}>
                        <Card className="contact-card">
                            <Card.Body>
                                <h4 className="mb-4">Contact Information</h4>
                                
                                <div className="contact-info">
                                    <div className="info-item">
                                        <FontAwesomeIcon icon={faPhone} className="info-icon" />
                                        <div>
                                            <h6>Phone</h6>
                                            <p>+{carData.contactNo}</p>
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
                                        <div>
                                            <h6>Email</h6>
                                            <p>{carData.email}</p>
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="info-icon" />
                                        <div>
                                            <h6>Address</h6>
                                            <p>{carData.address}, {carData.city}</p>
                                        </div>
                                    </div>

                                    <div className="info-item">
                                        <FontAwesomeIcon icon={faClock} className="info-icon" />
                                        <div>
                                            <h6>Posted On</h6>
                                            <p>{formatDate(carData.date)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <Button 
                                        variant={carData.active ? "danger" : "secondary"}
                                        className="w-100"
                                        onClick={handleCloseCar}
                                    >
                                        {carData.active ? "Close Listing" : "Listing Closed"}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <style jsx>{`
                .car-details-page {
                    background-color: #f4f6f8;
                    min-height: 100vh;
                    padding: 2.5rem 0;
                }

                .back-section {
                    margin-top: -1rem;
                    margin-bottom: 2rem;
                }

                .back-link {
                    color: #ed1c24;
                    text-decoration: none;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    gap: 0.75rem;
                    font-size: 1.1rem;
                    transition: all 0.3s ease;
                }

                .back-link:hover {
                    color: #d91414;
                    transform: translateX(-5px);
                }

                .image-gallery {
                    background: white;
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
                }

                .main-image {
                    width: 100%;
                    height: 550px;
                    position: relative;
                    overflow: hidden;
                    cursor: grab;
                }

                .main-image:active {
                    cursor: grabbing;
                }

                .main-image img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease;
                }

                .main-image:hover img {
                    transform: scale(1.02);
                }

                .image-navigation {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 1rem;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                .main-image:hover .image-navigation {
                    opacity: 1;
                }

                .nav-button {
                    background: rgba(237, 28, 36, 0.8);
                    color: white;
                    border: none;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    z-index: 2;
                }

                .nav-button:hover {
                    background: rgba(237, 28, 36, 1);
                    transform: scale(1.1);
                }

                .image-counter {
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                    background: rgba(0, 0, 0, 0.6);
                    color: white;
                    padding: 0.5rem 1rem;
                    border-radius: 20px;
                    font-size: 0.9rem;
                    z-index: 2;
                }

                .thumbnail-container {
                    display: flex;
                    gap: 0.75rem;
                    padding: 1.25rem;
                    background: white;
                    overflow-x: auto;
                }

                .thumbnail {
                    width: 120px;
                    height: 90px;
                    flex-shrink: 0;
                    cursor: pointer;
                    border-radius: 8px;
                    overflow: hidden;
                    opacity: 0.7;
                    transition: all 0.3s ease;
                    border: 2px solid transparent;
                }

                .thumbnail.active {
                    opacity: 1;
                    border: 2px solid #ed1c24;
                }

                .thumbnail:hover {
                    opacity: 0.9;
                    transform: translateY(-2px);
                }

                .thumbnail img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .section-title {
                    color: #333;
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-bottom: 1.5rem;
                }

                .section-title svg {
                    color: #ed1c24;
                }

                .specifications {
                    background: white;
                    padding: 1.5rem;
                    border-radius: 12px;
                    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
                }

                .availability-details {
                    margin-top: 1rem;
                }

                .availability-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    padding: 1rem;
                    background: #f8f9fa;
                    border-radius: 8px;
                    margin-bottom: 0.5rem;
                }

                .availability-item:last-child {
                    margin-bottom: 0;
                }

                .availability-item .spec-icon {
                    color: #ed1c24;
                    font-size: 1.2rem;
                }

                .availability-item h6 {
                    margin: 0;
                    color: #666;
                    font-size: 0.9rem;
                }

                .availability-item p {
                    margin: 0;
                    font-weight: 600;
                    color: #333;
                    font-size: 1rem;
                }

                .contact-card {
                    background: white;
                    border: none;
                    border-radius: 12px;
                    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
                }

                .info-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    margin-bottom: 1.5rem;
                }

                .info-icon {
                    color: #ed1c24;
                    font-size: 1.2rem;
                }

                @media (max-width: 768px) {
                    .main-image {
                        height: 350px;
                    }

                    .thumbnail {
                        width: 100px;
                        height: 75px;
                    }

                    .image-navigation {
                        opacity: 1;
                    }
                }

                .favorite-button {
                    background: none;
                    border: 2px solid #d9534f;
                    border-radius: 8px;
                    padding: 8px 16px;
                    color: #d9534f;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    transition: all 0.2s ease;
                    cursor: pointer;
                }

                .favorite-button:hover {
                    background: #fff1f1;
                }

                .favorite-button.is-favorite {
                    background: #d9534f;
                    color: white;
                }

                .favorite-button:disabled {
                    opacity: 0.7;
                    cursor: not-allowed;
                }

                .favorite-button svg {
                    font-size: 1.1rem;
                }
            `}</style>
        </div>
    );
};

export default SalesDetails;