import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useGlobalContext } from '../../../context/DataContext';
import '../dashboard_style.css';
import '../dashboard_2025.css';
import { API_ENDPOINT } from '../../../config/config';

const ClosedRequest = () => {
  const [selectSaved, setSelectSaved] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [buyData, setBuyData] = useState([]);
  const [salesCarsLength, setSalesCarsLength] = useState(0);
  const [buyCarsLength, setBuyCarsLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSaved = () => setSelectSaved(true);
  const handleChangeSaved1 = () => setSelectSaved(false);

  const getData = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/cars/inactive-sell-cars`);
      return res.data.data;
    } catch (error) {
      console.error('Error fetching sales data:', error);
      return [];
    }
  };

  const getBuyData = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/cars/inactive-buy-cars`);
      return res.data.data;
    } catch (error) {
      console.error('Error fetching buy data:', error);
      return [];
    }
  };

  const handleReopen = async (id, type) => {
    setIsLoading(true);
    try {
      const endpoint = type === 'sell' ? 'reopen-car-sell' : 'reopen-car-buy';
      await axios.put(`${API_ENDPOINT}/cars/${endpoint}?id=${id}`);
      
      // Refresh the data
      if (type === 'sell') {
        const data = await getData();
        setSalesData(data);
      } else {
        const data = await getBuyData();
        setBuyData(data);
      }
    } catch (error) {
      console.error('Error reopening request:', error);
    }
    setIsLoading(false);
  };

  const getCarLogo = (make) => {
    const logoMap = {
      'suzuki': '/assets/carslogos/suzuki.png',
      'toyota': '/assets/carslogos/toyota.png',
      'honda': '/assets/carslogos/honda.png',
      'kia': '/assets/carslogos/kia.png',
      'nissan': '/assets/carslogos/nissan.png',
      'hyundai': '/assets/carslogos/hyundai.png',
      'bmw': '/assets/carslogos/bmw.png',
      'mercedes': '/assets/carslogos/mercedes.png',
      'audi': '/assets/carslogos/audi.png'
    };
    
    const makeLower = make.toLowerCase();
    return logoMap[makeLower] || '/assets/carslogos/default.png';
  };

  useEffect(() => {
    getData().then((data) => setSalesData(data));
    getBuyData().then((data) => setBuyData(data));
  }, []);

  useEffect(() => {
    setSalesCarsLength(salesData.length);
  }, [salesData]);

  useEffect(() => {
    setBuyCarsLength(buyData.length);
  }, [buyData]);

  return (
    <div>
      <div className="content-section">
        <div className="tab-navigation">
          <button 
            className={!selectSaved ? 'tab-button active' : 'tab-button'} 
            onClick={handleChangeSaved1}
          >
            <span className="tab-text">Closed Sales</span>
            <span className="tab-count">{salesCarsLength}</span>
          </button>
          <button 
            className={selectSaved ? 'tab-button active' : 'tab-button'} 
            onClick={handleChangeSaved}
          >
            <span className="tab-text">Closed Purchases</span>
            <span className="tab-count">{buyCarsLength}</span>
          </button>
        </div>

        <div className="cars-container">
          {!selectSaved ? (
            // Closed Sales Cars
            salesData.length === 0 ? (
              <div className="no-data-container" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '50px 20px',
                textAlign: 'center',
                width: '100%',
                gridColumn: '1 / -1'
              }}>
                <img 
                  src="/assets/dashboard_images/no-data.svg" 
                  alt="No Data" 
                  style={{
                    width: '200px',
                    marginBottom: '20px'
                  }}
                />
                <h3 style={{
                  color: '#666',
                  marginBottom: '10px'
                }}>No Closed Sales</h3>
                <p style={{
                  color: '#888',
                  fontSize: '16px'
                }}>There are currently no closed or inactive car sale requests.</p>
              </div>
            ) : (
              salesData
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((car) => (
                  <div key={car._id} className="car-card-wrapper">
                    <Link to={`/saledetail/${car._id}`} style={{ textDecoration: 'none' }}>
                      <div className="car-card">
                        <div className="car-header">
                          <div className="car-brand">
                            <div className="logo-container">
                              <img 
                                src={getCarLogo(car.make.make)}
                                alt={car.make.make}
                                className="brand-logo"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/assets/carslogos/default.png';
                                }}
                              />
                            </div>
                            <div className="car-title">
                              <h2>{car.make.make} {car.make.model} {car.make.year}</h2>
                              <span className="car-variant">{car.make.version}</span>
                            </div>
                          </div>
                          <button className="bookmark-btn" aria-label="Bookmark">
                            <i className="fa fa-bookmark-o"></i>
                          </button>
                        </div>

                        <div className="car-info-grid">
                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-tachometer"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.milage}</span>
                              <span className="info-label">KM</span>
                            </div>
                          </div>

                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-gear"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.transmission}</span>
                              <span className="info-label">TRANSMISSION</span>
                            </div>
                          </div>

                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-palette"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.exteriorColor}</span>
                              <span className="info-label">COLOR</span>
                            </div>
                          </div>
                        </div>

                        <div className="car-footer">
                          <div className="location-date">
                            <div className="location">
                              <i className="fa fa-map-marker"></i>
                              <span>{car.registerCity}</span>
                            </div>
                            <div className="date">
                              <i className="fa fa-calendar"></i>
                              <span>{new Date(car.date).toLocaleDateString()}</span>
                            </div>
                          </div>
                          {car.priority === "High" && (
                            <div className="price-tag">HIGHEST PRICE</div>
                          )}
                        </div>
                      </div>
                    </Link>
                    <button 
                      className="reopen-button" 
                      onClick={() => handleReopen(car._id, 'sell')}
                      disabled={isLoading}
                    >
                      <i className="fa fa-refresh"></i>
                      Reopen Request
                    </button>
                  </div>
                ))
            )
          ) : (
            // Buy Cars
            buyData.length === 0 ? (
              <div className="no-data-container" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '50px 20px',
                textAlign: 'center',
                width: '100%',
                gridColumn: '1 / -1'
              }}>
                <img 
                  src="/assets/dashboard_images/no-data.svg" 
                  alt="No Data" 
                  style={{
                    width: '200px',
                    marginBottom: '20px'
                  }}
                />
                <h3 style={{
                  color: '#666',
                  marginBottom: '10px'
                }}>No Closed Purchases</h3>
                <p style={{
                  color: '#888',
                  fontSize: '16px'
                }}>There are currently no closed or inactive car purchase requests.</p>
              </div>
            ) : (
              buyData
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((car) => (
                  <div key={car._id} className="car-card-wrapper">
                    <Link to={`/buydetail/${car._id}`} style={{ textDecoration: 'none' }}>
                      <div className="car-card">
                        <div className="car-header">
                          <div className="car-brand">
                            <div className="logo-container">
                              <img 
                                src={getCarLogo(car.make.make)}
                                alt={car.make.make}
                                className="brand-logo"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/assets/carslogos/default.png';
                                }}
                              />
                            </div>
                            <div className="car-title">
                              <h2>{car.make.make} {car.make.model} {car.from}-{car.to}</h2>
                              <span className="car-variant">{car.make.version}</span>
                            </div>
                          </div>
                          <button className="bookmark-btn" aria-label="Bookmark">
                            <i className="fa fa-bookmark-o"></i>
                          </button>
                        </div>

                        <div className="car-info-grid">
                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-money"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.minBudget} - {car.maxBudget}</span>
                              <span className="info-label">BUDGET (PKR)</span>
                            </div>
                          </div>
                          
                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-gear"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.transmission}</span>
                              <span className="info-label">TRANSMISSION</span>
                            </div>
                          </div>

                          <div className="info-item">
                            <div className="info-icon">
                              <i className="fa fa-palette"></i>
                            </div>
                            <div className="info-content">
                              <span className="info-value">{car.exteriorColor.join(', ')}</span>
                              <span className="info-label">COLOR</span>
                            </div>
                          </div>
                        </div>

                        <div className="car-footer">
                          <div className="location-date">
                            <div className="location">
                              <i className="fa fa-map-marker"></i>
                              <span>{car.city}</span>
                            </div>
                            <div className="date">
                              <i className="fa fa-calendar"></i>
                              <span>{new Date(car.date).toLocaleDateString()}</span>
                            </div>
                          </div>
                          {car.urgency === "High" && (
                            <div className="price-tag">URGENT</div>
                          )}
                        </div>
                      </div>
                    </Link>
                    <button 
                      className="reopen-button" 
                      onClick={() => handleReopen(car._id, 'buy')}
                      disabled={isLoading}
                    >
                      <i className="fa fa-refresh"></i>
                      Reopen Request
                    </button>
                  </div>
                ))
            )
          )}
        </div>
      </div>

      <style jsx>{`
        .content-section {
          padding: 20px;
          max-width: 1400px;
          margin: 0 auto;
        }

        .tab-navigation {
          display: flex;
          gap: 16px;
          margin-bottom: 32px;
          border-bottom: 1px solid #eee;
          padding-bottom: 8px;
        }

        .tab-button {
          display: flex;
          align-items: center;
          gap: 8px;
          background: none;
          border: none;
          padding: 8px 16px;
          color: #666;
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          position: relative;
        }

        .tab-button:after {
          content: '';
          position: absolute;
          bottom: -9px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #d9534f;
          transform: scaleX(0);
          transition: transform 0.2s ease;
        }

        .tab-button.active {
          color: #333;
        }

        .tab-button.active:after {
          transform: scaleX(1);
        }

        .tab-text {
          font-weight: 500;
        }

        .tab-count {
          background: #f8f9fa;
          color: #666;
          padding: 2px 8px;
          border-radius: 20px;
          font-size: 13px;
          min-width: 28px;
          text-align: center;
          transition: all 0.2s ease;
        }

        .tab-button.active .tab-count {
          background: #d9534f;
          color: white;
        }

        .cars-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 24px;
          padding: 20px 0;
        }

        .car-card {
          background: white;
          border-radius: 10px;
          padding: 16px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: all 0.2s ease;
          border: 1px solid #eee;
          height: 100%;
        }

        .car-card:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-color: #d9534f;
        }

        .logo-container {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 8px;
          padding: 6px;
          border: 1px solid #eee;
        }

        .brand-logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .car-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 16px;
        }

        .car-brand {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .car-title h2 {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          margin: 0;
          line-height: 1.2;
        }

        .car-variant {
          font-size: 13px;
          color: #666;
          display: block;
          margin-top: 2px;
        }

        .bookmark-btn {
          background: none;
          border: none;
          color: #d9534f;
          cursor: pointer;
          padding: 4px;
          opacity: 0.7;
          transition: all 0.2s ease;
        }

        .bookmark-btn:hover {
          opacity: 1;
          transform: scale(1.1);
        }

        .car-info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 12px;
          padding: 12px 0;
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
          margin: 12px 0;
        }

        .info-item {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .info-icon {
          width: 32px;
          height: 32px;
          background: #fff1f1;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .info-icon i {
          color: #d9534f;
          font-size: 16px;
        }

        .info-content {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .info-value {
          font-size: 14px;
          font-weight: 500;
          color: #333;
        }

        .info-label {
          font-size: 10px;
          color: #666;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        .car-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
        }

        .location-date {
          display: flex;
          gap: 16px;
          font-size: 13px;
          color: #666;
        }

        .location, .date {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .location i, .date i {
          color: #d9534f;
          font-size: 14px;
        }

        .price-tag {
          background: #d9534f;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        @media (max-width: 1200px) {
          .cars-container {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media (max-width: 768px) {
          .cars-container {
            grid-template-columns: 1fr;
            padding: 12px;
          }
          
          .car-info-grid {
            gap: 8px;
          }
          
          .info-icon {
            width: 28px;
            height: 28px;
          }
        }

        .car-card-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .reopen-button {
          width: 100%;
          padding: 10px;
          background: #28a745;
          color: white;
          border: none;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          transition: all 0.2s ease;
        }

        .reopen-button:hover {
          background: #218838;
          transform: translateY(-1px);
        }

        .reopen-button:disabled {
          background: #6c757d;
          cursor: not-allowed;
        }

        .reopen-button i {
          font-size: 14px;
        }

        @media (max-width: 768px) {
          .reopen-button {
            padding: 8px;
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default ClosedRequest;