import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../Other/ErrorMessage';
import { toast } from 'react-hot-toast';

const UserLogin = () => {
    const navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [login, setLogin] = React.useState({
        email: "",
        password: "",
    });
    const [error, setError] = React.useState(false);
    const [errorData, setErrorData] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleLoginData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLogin({ ...login, [name]: value });
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        const { email, password } = login;

        try {
            var data = JSON.stringify({
                "email": email,
                "password": password
            });

            var config = {
                method: 'post',
                url: `https://api.aboutcars.pk/api/admin/employeelogin`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            console.log('Sending login request with:', { email });
            const res = await axios(config);
            console.log('Login response:', res.data);

            if(res.data.code === 400 || res.data.Status === "error"){
                setErrorData(res.data.message || "Login failed");
                setError(true);
                setOpen(true);
                setLoading(false);
                return;
            }

            if (res.data.code === 200 || res.data.Status === "success") {
                // Clear any existing tokens
                localStorage.removeItem('adminToken');
                
                // Ensure permissions is an array
                const permissions = res.data.data.permissions || [];
                console.log('Raw permissions from server:', res.data.data.permissions);
                
                // Store user data including permissions
                const userData = {
                    ...res.data,
                    data: {
                        ...res.data.data,
                        permissions: permissions
                    }
                };
                
                console.log('Storing user data in localStorage:', userData);
                localStorage.setItem('user', JSON.stringify(userData));
                
                // Show success message
                toast.success('Login successful!');
                
                // Redirect to dashboard
                console.log('Redirecting to dashboard...');
                setTimeout(() => {
                    navigate('/carsDashboard');
                }, 500);
            } else {
                // Handle unexpected response format
                console.error('Unexpected response format:', res.data);
                setErrorData("Unexpected response from server");
                setError(true);
                setOpen(true);
            }
            
            setLoading(false);
        } catch (error) {
            console.error('Login error:', error);
            console.error('Error response:', error.response?.data);
            setLoading(false);
            setErrorData(error.response?.data?.message || "Invalid email or password");
            setError(true);
            setOpen(true);
        }
    }

    // Check if user is already logged in
    useEffect(() => {
        const userInfo = localStorage.getItem('user');
        if (userInfo) {
            navigate('/carsDashboard');
        }
    }, [navigate]);

    return (
        <div>
            {error === true ? <ErrorMessage open={open} setOpen={setOpen} data={errorData} /> : null}
            <div className="row login_wrapper">
                <div className="col-lg-5 col-sm-12">
                    <div className="login_image">
                        <img src="assets/dashboard_images/banner.png" alt='...' />
                    </div>
                </div>
                <div className="col-lg-7 col-sm-12">
                    <div className="login_form">
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="login_form_header">
                                <h1>Login to your account</h1>
                            </div>
                            <div className='login_inputs pt-2'>
                                <form className="login-form" method="POST">
                                    <div className="form-input-material">
                                        <input 
                                            type="email" 
                                            name="email" 
                                            id="username" 
                                            placeholder=" " 
                                            autoComplete=" " 
                                            required 
                                            className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.email}
                                        />
                                        <label htmlFor="username">Email</label>
                                    </div>
                                    <div className="form-input-material position-relative">
                                        <input 
                                            type={passwordShown ? "text" : "password"} 
                                            name="password" 
                                            id="password" 
                                            placeholder=" " 
                                            autoComplete="off" 
                                            required 
                                            className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.password}
                                            style={{ width: '330px !important' }}
                                        />
                                        <i 
                                            className='fa fa-eye position-absolute'
                                            style={{
                                                right: "120px",
                                                top: "25px",
                                                fontSize: "20px",
                                                padding: "5px",
                                                cursor: "pointer",
                                                borderRadius: "50%",
                                                color: "#fff"
                                            }}
                                            onClick={togglePassword}
                                        ></i>
                                        <label htmlFor="password">Password</label>
                                        <button type="button" className='position-absolute'>Forgot?</button>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <button
                                            type="submit" 
                                            className="login_button"
                                            onClick={handleLoginSubmit}
                                            disabled={loading}
                                        >
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserLogin