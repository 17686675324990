import React, { useState, useEffect } from 'react';
import './AdminStyles.css';
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { API_ENDPOINT } from '../../config/config';

const filter = createFilterOptions();

const AddCar = () => {
    const [carData, setCarData] = useState({
        make: '',
        model: '',
        year: new Date().getFullYear(),
        exteriorColor: '',
        interiorColor: '',
        milage: '',
        transmission: 'Automatic',
        engineType: '',
        engineCapacity: '',
        description: '',
        price: '',
        location: '',
        features: [],
        carImages: [],
    });

    const [stockData, setStockData] = useState({
        quantity: 1,
        status: 'available',
        location: '',
        purchasePrice: '',
        sellingPrice: '',
        supplier: {
            name: '',
            contact: '',
            email: ''
        },
        notes: '',
        documents: []
    });

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });
    const [availableCar, setAvailableCar] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [selectCar, setSelectCar] = useState({
        year: "year",
        make: "make",
        model: "model",
        version: "version",
    });

    const availableColors = [
        { title: "Red" }, { title: "Green" }, { title: "Yellow" }, { title: "Blue" },
        { title: "Black" }, { title: "White" }, { title: "Silver" }, { title: "Gray" },
        { title: "Brown" }, { title: "Orange" }, { title: "Pink" }, { title: "Purple" },
        { title: "Gold" }, { title: "Beige" }, { title: "Maroon" }, { title: "Navy" }
    ];

    const availableCities = [
        { title: "Rawalpindi" }, { title: "Lahore" }, { title: "Islamabad" },
        { title: "Karachi" }, { title: "Faisalabad" }, { title: "Multan" },
        { title: "Peshawar" }, { title: "Quetta" }, { title: "Sialkot" },
        { title: "Gujranwala" }
    ];

    useEffect(() => {
        // Initialize available years
        const years = Array.from({ length: new Date().getFullYear() - 1939 }, (_, i) => new Date().getFullYear() - i);
        setAvailableYears(years);

        // Fetch available cars
        const fetchCars = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}cars/availablecars`);
                setAvailableCar(response.data.data);
            } catch (error) {
                console.error('Error fetching cars:', error);
            }
        };
        fetchCars();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCarData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCarSelection = (type, value) => {
        setSelectCar(prev => ({
            ...prev,
            [type]: value
        }));
        
        setCarData(prev => ({
            ...prev,
            make: type === 'make' ? value : prev.make,
            model: type === 'model' ? value : prev.model,
            year: type === 'year' ? value : prev.year
        }));
    };

    const handleFeatureChange = (e) => {
        const { value, checked } = e.target;
        setCarData(prev => ({
            ...prev,
            features: checked 
                ? [...prev.features, value]
                : prev.features.filter(feature => feature !== value)
        }));
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);

        // Create preview URLs
        const previewUrls = files.map(file => URL.createObjectURL(file));
        setCarData(prev => ({
            ...prev,
            carImages: previewUrls
        }));
    };

    const handleStockInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('supplier.')) {
            const supplierField = name.split('.')[1];
            setStockData(prev => ({
                ...prev,
                supplier: {
                    ...prev.supplier,
                    [supplierField]: value
                }
            }));
        } else {
            setStockData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleDocumentUpload = (e) => {
        const files = Array.from(e.target.files);
        // Create preview URLs for documents
        const documentUrls = files.map(file => URL.createObjectURL(file));
        setStockData(prev => ({
            ...prev,
            documents: [...prev.documents, ...documentUrls]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage({ type: '', text: '' });

        try {
            // Create FormData for image upload
            const formData = new FormData();
            images.forEach(image => {
                formData.append('carImages', image);
            });

            // First upload images
            const imageUploadResponse = await fetch(`${process.env.REACT_APP_API_URL}upload`, {
                method: 'POST',
                body: formData
            });

            if (!imageUploadResponse.ok) throw new Error('Image upload failed');

            const imageData = await imageUploadResponse.json();
            const imagePaths = imageData.paths;

            // Submit car data with image paths
            const carSubmitResponse = await fetch(`${process.env.REACT_APP_API_URL}api/cars/add-sell-car`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...carData,
                    carImages: imagePaths
                })
            });

            if (!carSubmitResponse.ok) throw new Error('Failed to add car');

            const carResponseData = await carSubmitResponse.json();

            // Submit stock data with car ID
            const stockSubmitResponse = await fetch(`${process.env.REACT_APP_API_URL}api/stock/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...stockData,
                    carId: carResponseData.data._id
                })
            });

            if (!stockSubmitResponse.ok) throw new Error('Failed to add stock information');

            setMessage({ type: 'success', text: 'Car and stock information added successfully!' });
            
            // Reset form
            setCarData({
                make: '',
                model: '',
                year: new Date().getFullYear(),
                exteriorColor: '',
                interiorColor: '',
                milage: '',
                transmission: 'Automatic',
                engineType: '',
                engineCapacity: '',
                description: '',
                price: '',
                location: '',
                features: [],
                carImages: [],
            });
            setStockData({
                quantity: 1,
                status: 'available',
                location: '',
                purchasePrice: '',
                sellingPrice: '',
                supplier: {
                    name: '',
                    contact: '',
                    email: ''
                },
                notes: '',
                documents: []
            });
            setImages([]);
            setSelectCar({
                year: "year",
                make: "make",
                model: "model",
                version: "version",
            });
        } catch (error) {
            setMessage({ type: 'error', text: error.message });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="admin-container">
            <h2>Add New Car</h2>
            {message.text && (
                <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                    {message.text}
                </div>
            )}
            <form onSubmit={handleSubmit} className="add-car-form">
                <div className="form-section">
                    <h3>Basic Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Year</label>
                            <Select
                                value={selectCar.year}
                                onChange={(e) => handleCarSelection('year', e.target.value)}
                                displayEmpty
                                className="form-select"
                            >
                                <MenuItem value="year">Select Year</MenuItem>
                                {availableYears.map(year => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="form-group">
                            <label>Make</label>
                            <Select
                                value={selectCar.make}
                                onChange={(e) => handleCarSelection('make', e.target.value)}
                                displayEmpty
                                className="form-select"
                            >
                                <MenuItem value="make">Select Make</MenuItem>
                                {Array.from(new Set(availableCar.map(car => car.make))).map(make => (
                                    <MenuItem key={make} value={make}>{make}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="form-group">
                            <label>Model</label>
                            <Select
                                value={selectCar.model}
                                onChange={(e) => handleCarSelection('model', e.target.value)}
                                displayEmpty
                                className="form-select"
                            >
                                <MenuItem value="model">Select Model</MenuItem>
                                {availableCar
                                    .filter(car => car.make === selectCar.make)
                                    .map(car => (
                                        <MenuItem key={car.model} value={car.model}>{car.model}</MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Vehicle Details</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Exterior Color</label>
                            <Autocomplete
                                value={carData.exteriorColor}
                                onChange={(event, newValue) => {
                                    setCarData(prev => ({
                                        ...prev,
                                        exteriorColor: newValue ? newValue.title : ''
                                    }));
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                options={availableColors}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.title;
                                }}
                                renderOption={(option) => option.title}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Color" />
                                )}
                            />
                        </div>
                        <div className="form-group">
                            <label>Interior Color</label>
                            <Autocomplete
                                value={carData.interiorColor}
                                onChange={(event, newValue) => {
                                    setCarData(prev => ({
                                        ...prev,
                                        interiorColor: newValue ? newValue.title : ''
                                    }));
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                options={availableColors}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.title;
                                }}
                                renderOption={(option) => option.title}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Color" />
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Mileage (km)</label>
                            <input
                                type="number"
                                name="milage"
                                value={carData.milage}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Transmission</label>
                            <Select
                                name="transmission"
                                value={carData.transmission}
                                onChange={handleInputChange}
                                displayEmpty
                                className="form-select"
                            >
                                <MenuItem value="Automatic">Automatic</MenuItem>
                                <MenuItem value="Manual">Manual</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Engine Type</label>
                            <input
                                type="text"
                                name="engineType"
                                value={carData.engineType}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Engine Capacity (cc)</label>
                            <input
                                type="text"
                                name="engineCapacity"
                                value={carData.engineCapacity}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Additional Information</h3>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            name="description"
                            value={carData.description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Price (PKR)</label>
                            <input
                                type="number"
                                name="price"
                                value={carData.price}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Location</label>
                            <Autocomplete
                                value={carData.location}
                                onChange={(event, newValue) => {
                                    setCarData(prev => ({
                                        ...prev,
                                        location: newValue ? newValue.title : ''
                                    }));
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    return filtered;
                                }}
                                options={availableCities}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.title;
                                }}
                                renderOption={(option) => option.title}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label="Select City" />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Features</h3>
                    <div className="features-grid">
                        {['ABS', 'Air Conditioning', 'Airbags', 'AM/FM Radio', 'Power Steering', 
                          'Power Windows', 'USB and Auxiliary Cable', 'Alloy Rims'].map(feature => (
                            <div key={feature} className="feature-item">
                                <input
                                    type="checkbox"
                                    id={feature}
                                    value={feature}
                                    checked={carData.features.includes(feature)}
                                    onChange={handleFeatureChange}
                                />
                                <label htmlFor={feature}>{feature}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="form-section">
                    <h3>Images</h3>
                    <div className="form-group">
                        <label className="image-upload-label">
                            Upload Images
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleImageChange}
                                required
                            />
                        </label>
                        <div className="image-preview">
                            {carData.carImages.map((image, index) => (
                                <div key={index} className="preview-item">
                                    <img src={image} alt={`Preview ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Stock Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Quantity</label>
                            <input
                                type="number"
                                name="quantity"
                                value={stockData.quantity}
                                onChange={handleStockInputChange}
                                min="1"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <select
                                name="status"
                                value={stockData.status}
                                onChange={handleStockInputChange}
                                required
                            >
                                <option value="available">Available</option>
                                <option value="reserved">Reserved</option>
                                <option value="sold">Sold</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Location</label>
                            <input
                                type="text"
                                name="location"
                                value={stockData.location}
                                onChange={handleStockInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Purchase Price</label>
                            <input
                                type="number"
                                name="purchasePrice"
                                value={stockData.purchasePrice}
                                onChange={handleStockInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Selling Price</label>
                            <input
                                type="number"
                                name="sellingPrice"
                                value={stockData.sellingPrice}
                                onChange={handleStockInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-section">
                        <h4>Supplier Information</h4>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Supplier Name</label>
                                <input
                                    type="text"
                                    name="supplier.name"
                                    value={stockData.supplier.name}
                                    onChange={handleStockInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Supplier Contact</label>
                                <input
                                    type="text"
                                    name="supplier.contact"
                                    value={stockData.supplier.contact}
                                    onChange={handleStockInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Supplier Email</label>
                                <input
                                    type="email"
                                    name="supplier.email"
                                    value={stockData.supplier.email}
                                    onChange={handleStockInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Notes</label>
                            <textarea
                                name="notes"
                                value={stockData.notes}
                                onChange={handleStockInputChange}
                                rows="3"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Documents</label>
                            <input
                                type="file"
                                multiple
                                onChange={handleDocumentUpload}
                                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-actions">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Adding...' : 'Add Car'}
                </button>
                </div>
            </form>
        </div>
    );
};

export default AddCar; 