import React from "react";
import "./buy_style.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useGlobalContext } from "../../context/DataContext";
import { API_ENDPOINT, getValidApiUrl } from '../../config/config';
import ErrorMessage from "../Other/ErrorMessage";

const BuySubmit = (props) => {
  const { data } = props;
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    setHandleNext,
    setHandleNext2,
    setDivHandle,
    setOpenWait,
  } = useGlobalContext();
  const navigate = useNavigate();

  
  const handleBack = () => {
    setDivHandle(false);
    setHandleNext(true);
    setHandleNext2(true);
    // console.log(divHandle);
  };

  const onSubmit = async () => {
    try {
      const json = {
        from: data.selectFrom,
        to: data.selectTo,
        make: data.selectCar,
        registerCity: data.personName,
        transmission: data.selectTrans,
        exteriorColor: data.extColor,
        minBudget: data.minBudget,
        maxBudget: data.maxBudget,
        urgency: data.selectAvailable,
        name: data.contact.name,
        email: data.contact.email,
        contactNo: data.contact.number,
        city: data.contact.city,
        address: data.contact.address,
        notes: data.contact.notes,
      };

      setOpenWait(true);
      
      const url = getValidApiUrl('cars/add-buy');
      if (!url) {
        throw new Error('Invalid API URL configuration');
      }

      const res = await axios.post(url, { data: json }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      setOpenWait(false);
      navigate("/successfull");
      // console.log(res);
    } catch (error) {
      setOpenWait(false);
      console.error('Error submitting buy request:', error);
      setError(true);
      setErrorMessage(error.response?.data?.message || "Failed to submit request. Please try again.");
    }
  };

  return (
    <div>
      <ErrorMessage open={error} setOpen={setError} data={errorMessage} />
      <div className="review-container">
        <section className="buySubmit">
          <div className="title-text">
            <h1>Review Your Request</h1>
            <p className="subtitle">Please review your car buying requirements before submitting</p>
                </div>
          <div className="review-content">
            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">1</div>
                <h2>Car Information</h2>
              </div>
              <div className="info-grid">
                <div className="info-item">
                  <h4>Year Range</h4>
                  <p>{data.selectFrom} - {data.selectTo}</p>
                </div>
                <div className="info-item">
                  <h4>Make & Model</h4>
                  <p>{data.selectCar.make} {data.selectCar.model} {data.selectCar.version}</p>
                </div>
                <div className="info-item">
                  <h4>Registration City</h4>
                  <p>{data.personName ? data.personName.join(", ") : ""}</p>
                </div>
                <div className="info-item">
                  <h4>Transmission</h4>
                  <p>{data.selectTrans.toUpperCase()}</p>
                </div>
              </div>
            </div>

            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">2</div>
                <h2>Color & Budget Preferences</h2>
              </div>
              <div className="info-grid">
                <div className="info-item">
                  <h4>Exterior Colors</h4>
                  <p>{data.extColor ? data.extColor.join(", ") : ""}</p>
                </div>
                <div className="info-item">
                  <h4>Budget Range</h4>
                  <p>Rs. {Number(data.minBudget).toLocaleString()} - Rs. {Number(data.maxBudget).toLocaleString()}</p>
                </div>
                <div className="info-item">
                  <h4>Urgency Level</h4>
                  <p>{data.selectAvailable}</p>
                </div>
              </div>
            </div>

            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">3</div>
                <h2>Contact Information</h2>
                  </div>
              <div className="info-grid">
                <div className="info-item">
                  <h4>Name</h4>
                  <p>{data.contact.name}</p>
                </div>
                <div className="info-item">
                      <h4>Contact Number</h4>
                  <p>{data.contact.number}</p>
                    </div>
                <div className="info-item">
                  <h4>Email</h4>
                  <p>{data.contact.email || "Not provided"}</p>
                </div>
                <div className="info-item">
                  <h4>City</h4>
                  <p>{data.contact.city}</p>
                </div>
                <div className="info-item full-width">
                  <h4>Address</h4>
                  <p>{data.contact.address}</p>
                </div>
                {data.contact.notes && (
                  <div className="info-item full-width">
                    <h4>Additional Notes</h4>
                    <p>{data.contact.notes}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="action-buttons">
              <button className="back-button" onClick={handleBack}>
                Edit Request
                </button>
              <button className="submit-button" onClick={onSubmit}>
                  Submit Request
                </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BuySubmit;
