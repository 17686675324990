import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast';

import "./admin_style.css";
import "../../App.css";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel, OutlinedInput } from '@material-ui/core';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "auto",
        width: "700px !important",
    },
}));


const UsersArea = () => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [selectRole, setSelectRole] = React.useState("");
    const [selectPermission, setSelectPermission] = React.useState([]);
    const [showEmployee, setShowEmployee] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [editUserForm, setEditUserForm] = useState({
        fullName: '',
        contactNumber: '',
        email: '',
        role: '',
        permissions: []
    });

    // Ensure API URL is properly formatted
    const API_URL = process.env.REACT_APP_API_URL 
        ? process.env.REACT_APP_API_URL.endsWith('/') 
            ? process.env.REACT_APP_API_URL 
            : `${process.env.REACT_APP_API_URL}/`
        : 'https://api.aboutcars.pk/api/';  // Updated default to match server config

    // Function to ensure URL is valid
    const getValidUrl = (endpoint) => {
        try {
            if (!endpoint) {
                throw new Error('Endpoint is required');
            }

            // Remove 'admin/' prefix if it exists since it's already part of the API URL
            const cleanEndpoint = endpoint.startsWith('admin/') ? endpoint.slice(6) : endpoint;
            
            // Get the base URL without trailing slash
            let baseUrl = API_URL;
            if (baseUrl.endsWith('/')) {
                baseUrl = baseUrl.slice(0, -1);
            }

            // Construct URL parts
            const urlParts = baseUrl.split('/');
            const host = urlParts[0] + '//' + urlParts[2];
            
            // Ensure we have all required parts
            const fullUrl = `${host}/api/admin/${cleanEndpoint}`;
            
            // Validate the URL
            try {
                new URL(fullUrl);
            } catch (e) {
                console.error('Invalid URL formed:', fullUrl);
                throw new Error(`Invalid URL: ${fullUrl}`);
            }
            
            console.log('Making request to:', fullUrl); // Debug log
            return fullUrl;
        } catch (error) {
            console.error('URL formation error:', error);
            return null;
        }
    };

    const permissionNames = [
        'Add User',
        'Edit User Permission',
        'Delete User',
        'See all Sell Cars',
        'See all Buy Cars',
        'Add to Stock',
        'Remove from Stock',
        'Close Requests',
        'Reopen Requests',
        'Add Car Models',
        'Edit Car Models',
        'Delete Car Models',
        'View Analytics',
        'Export Reports',
        'Manage Favorites',
        'View Customer Details'
    ];

    const permissionGroups = {
        'User Management': [
            'Add User',
            'Edit User Permission',
            'Delete User'
        ],
        'Car Management': [
            'See all Sell Cars',
            'See all Buy Cars',
            'Add to Stock',
            'Remove from Stock',
            'Close Requests',
            'Reopen Requests'
        ],
        'Model Management': [
            'Add Car Models',
            'Edit Car Models',
            'Delete Car Models'
        ],
        'Analytics & Reports': [
            'View Analytics',
            'Export Reports'
        ],
        'Customer Features': [
            'Manage Favorites',
            'View Customer Details'
        ]
    };

    // const ITEM_HEIGHT = 48;
    // const ITEM_PADDING_TOP = 8;
    // const MenuProps = {
    //     PaperProps: {
    //         style: {
    //             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //             width: 250,
    //         },
    //     },
    // };

    const handleChangeRole = (event) => {
        const newRole = event.target.value;
        setSelectRole(newRole);
        
        // If admin is selected, automatically select all permissions
        if (newRole === "admin") {
            setSelectPermission([
                'Add User', 'Edit User Permission', 'Delete User',
                'See all Sell Cars', 'See all Buy Cars', 'Add to Stock',
                'Remove from Stock', 'Close Requests', 'Reopen Requests',
                'Add Car Models', 'Edit Car Models', 'Delete Car Models',
                'View Analytics', 'Export Reports', 'Manage Favorites',
                'View Customer Details'
            ]);
        } else {
            // If changing from admin to moderator, reset permissions
            setSelectPermission([]);
        }
    };
    const handleChangePermission = (event) => {
        // Ensure we're working with string values, not objects
        const selectedPermissions = event.target.value.map(val => 
            typeof val === 'object' && val.hasOwnProperty('title') 
                ? val.title 
                : val
        );
        
        console.log('Setting permissions in handleChangePermission:', selectedPermissions);
        
        // If we're editing a user, update the editUserForm
        if (editingUser) {
            setEditUserForm(prev => ({
                ...prev,
                permissions: selectedPermissions
            }));
        } else {
            // Otherwise update the selectPermission state
            setSelectPermission(selectedPermissions);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleOpenDelete = (employee) => {
        setSelectedEmployee(employee);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleUpdate = (employee) => {
        setSelectedEmployee(employee);
        setUpdateValue({
            fullNameUpdate: employee.fullName,
            contactNumberUpdate: employee.contactNo
        });
        setOpenUpdate(true);
    };
    const handleUpdateClose = () => {
        setOpenUpdate(false);
    };


    // Start of sending the data to the server 
    const [userValue, setUserValue] = React.useState({
        fullName: "",
        password: "",
        contactNumber: "",
        emailAddress: "",
    });
    const handleChangeUserValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserValue({ ...userValue, [name]: value });
    }
    const handleSaveEmployee = async () => {
        try {
            const { fullName, contactNumber, emailAddress, password } = userValue;
            if (!fullName || !contactNumber || !emailAddress || !password || !selectRole) {
                throw new Error('Please fill all the fields');
            }

            const url = getValidUrl('admin/add-employee');
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            // Ensure permissions is correctly formatted as an array of strings
            const permissions = selectRole === 'admin' 
                ? [...permissionNames] // Create a copy to avoid reference issues
                : selectPermission.map(perm => 
                    typeof perm === 'object' && perm.hasOwnProperty('title') 
                        ? perm.title 
                        : perm
                  );
            
            console.log('Sending permissions:', permissions); // Debug log
            console.log('Permissions type:', Array.isArray(permissions) ? 'Array' : typeof permissions);
            console.log('Permissions length:', permissions.length);

            const data = {
                fullName: userValue.fullName,
                password: userValue.password,
                contactNo: userValue.contactNumber,
                email: userValue.emailAddress,
                role: selectRole,
                permissions: permissions // Use the correctly formatted permissions
            };

            console.log('Sending data to server:', data); // Debug log
            console.log('Data stringified:', JSON.stringify(data)); // Debug log

            // Send data directly without JSON.stringify
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            console.log('Server response:', response.data); // Debug server response
            console.log('Full response:', response); // Debug full response
            
            if (response.data.Status === "success") {
                setOpen(false);
                // Reset form
                setUserValue({
                    fullName: "",
                    password: "",
                    contactNumber: "",
                    emailAddress: "",
                });
                setSelectRole("");
                setSelectPermission([]);
                
            // Refresh the employee list
            const updatedData = await showData();
            setShowEmployee(updatedData || []);
                
                toast.success('Employee added successfully');
            } else {
                throw new Error(response.data.message || 'Failed to add employee');
            }
        } catch (error) {
            console.error('Error adding employee:', error);
            setError(error.message || 'Failed to add employee');
            toast.error(error.message || 'Failed to add employee');
        }
    };

    // Show employee data 
    const showData = async () => {
        try {
            setIsLoading(true);
            setError(null);
            
            const url = getValidUrl('employees');
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            console.log('Fetching employees from:', url); // Debug log
            
            const res = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                validateStatus: function (status) {
                    return status >= 200 && status < 300; // default
                }
            });

            console.log('Server response:', res); // Debug server response

            if (!res.data) {
                throw new Error('No data received from server');
            }

            // Handle different response formats
            const employees = res.data.data?.employee || res.data.employee || res.data;
            
            if (!employees) {
                throw new Error('Invalid response format from server');
            }

            // Ensure each employee has a permissions array
            const processedEmployees = Array.isArray(employees) ? employees.map(employee => {
                // If permissions is undefined or null, set it to an empty array
                if (!employee.permissions) {
                    employee.permissions = [];
                }
                // If permissions is not an array, try to convert it
                else if (!Array.isArray(employee.permissions)) {
                    try {
                        employee.permissions = JSON.parse(employee.permissions);
                    } catch (e) {
                        console.error('Error parsing permissions for employee:', employee._id, e);
                        employee.permissions = [];
                    }
                }
                return employee;
            }) : [];

            console.log('Processed employees:', processedEmployees);
            
            return processedEmployees;

        } catch (error) {
            console.error('Error fetching employees:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch employees';
            console.error('Error details:', errorMessage);
            if (error.response) {
                console.error('Server response:', error.response.data);
            }
            setError(errorMessage);
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        showData().then((data) => {
            console.log('Received employee data:', data); // Debug log
            setShowEmployee(data || []);
        });
    }, []);

    // Delete Employee 
    const handleDelete = async (id) => {
        try {
            // Confirm deletion
            const confirmDelete = window.confirm('Are you sure you want to delete this employee?');
            if (!confirmDelete) return;

            const url = getValidUrl(`admin/deleteemployee/${id}`);
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            const response = await axios.get(url);

            if (response.data.Status === "Success") {
            // Refresh the employee list after deletion
            const updatedData = await showData();
            setShowEmployee(updatedData || []);
                
                // Show success message
                toast.success('Employee deleted successfully');
            } else {
                throw new Error(response.data.message || 'Failed to delete employee');
            }
        } catch (error) {
            console.error('Error deleting employee:', error);
            toast.error(error.message || 'Failed to delete employee');
        }
    };


    // Update Employee
    const [updateValue, setUpdateValue] = React.useState({
        fullNameUpdate: "",
        contactNumberUpdate: "",
    });

    const handleChangeUpdateValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUpdateValue({ ...updateValue, [name]: value });
    }


    const handleUpdateUser = async (id) => {
        const { fullNameUpdate, contactNumberUpdate } = updateValue;
        try {
            if (!fullNameUpdate && !contactNumberUpdate) {
                throw new Error('Please fill the fields');
            }

            const url = getValidUrl(`admin/updateemployee/${id}`);
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            // Ensure permissions is correctly formatted as an array of strings
            let permissions;
            if (editingUser) {
                permissions = editUserForm.role === 'admin' 
                    ? [...permissionNames] // Create a copy to avoid reference issues
                    : editUserForm.permissions.map(perm => 
                        typeof perm === 'object' && perm.hasOwnProperty('title') 
                            ? perm.title 
                            : perm
                      );
            } else {
                permissions = selectRole === 'admin'
                    ? [...permissionNames] // Create a copy to avoid reference issues
                    : selectPermission.map(perm => 
                        typeof perm === 'object' && perm.hasOwnProperty('title') 
                            ? perm.title 
                            : perm
                      );
            }
            
            console.log('Updating with permissions:', permissions); // Debug log

            const data = {
                fullName: editingUser ? editUserForm.fullName : fullNameUpdate,
                contactNo: editingUser ? editUserForm.contactNumber : contactNumberUpdate,
                role: editingUser ? editUserForm.role : selectRole,
                permissions: permissions
            };

            console.log('Sending update data to server:', data); // Debug log

            // Send data directly without JSON.stringify
            const response = await axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Server response:', response.data); // Debug server response

            if (response.data.Status === "success") {
            setOpenUpdate(false);
            // Refresh the employee list after update
            const updatedData = await showData();
            setShowEmployee(updatedData || []);
                
                // Reset editing state
                setEditingUser(null);
                setEditUserForm({
                    fullName: '',
                    contactNumber: '',
                    email: '',
                    role: '',
                    permissions: []
                });
                
                toast.success('Employee updated successfully');
            } else {
                throw new Error(response.data.message || 'Failed to update employee');
            }
        } catch (error) {
            console.error('Error updating employee:', error);
            setError(error.message || 'Failed to update employee');
            toast.error(error.message || 'Failed to update employee');
        }
    };

    const renderPermissionGroups = () => {
        // Determine which permissions to use based on whether we're editing a user
        let currentPermissions;
        
        if (editingUser) {
            // For editing users
            currentPermissions = editUserForm.role === 'admin' 
                ? [...permissionNames] // Create a copy to avoid reference issues
                : (Array.isArray(editUserForm.permissions) ? editUserForm.permissions : []);
        } else {
            // For new users
            currentPermissions = selectPermission;
        }

        // Debug log to check what's in the permissions array
        console.log('Current permissions:', currentPermissions);

        return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="permissions-label">Select Permissions</InputLabel>
            <Select
                labelId="permissions-label"
                id="permissions-select"
                multiple
                value={currentPermissions}
                onChange={handleChangePermission}
                input={<OutlinedInput label="Permissions" />}
                renderValue={(selected) => (
                    <div>
                        {selected.map(value => {
                            // Convert objects to strings if needed
                            const displayValue = typeof value === 'object' && value.hasOwnProperty('title')
                                ? value.title
                                : value;
                            return displayValue;
                        }).join(', ')}
                    </div>
                )}
            >
                {Object.entries(permissionGroups).map(([groupName, groupPermissions]) => [
                    <MenuItem disabled key={groupName} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {groupName}
                    </MenuItem>,
                    ...groupPermissions.map(permission => (
                        <MenuItem 
                            key={permission} 
                            value={permission} 
                            style={{ paddingLeft: '32px' }}
                        >
                            <i className={`fa ${getPermissionIcon(permission)} me-2`}></i>
                            {permission}
                </MenuItem>
                    ))
                ])}
            </Select>
            <div className="d-flex gap-2 mt-2">
                <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                        if (editingUser) {
                            setEditUserForm(prev => ({
                                ...prev,
                                permissions: []
                            }));
                        } else {
                            setSelectPermission([]);
                        }
                    }}
                    type="button"
                >
                    Clear All
                </button>
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                        const allPermissions = [...permissionNames]; // Create a copy to avoid reference issues
                        if (editingUser) {
                            setEditUserForm(prev => ({
                                ...prev,
                                permissions: allPermissions
                            }));
                        } else {
                            setSelectPermission(allPermissions);
                        }
                    }}
                    type="button"
                >
                    Select All
                </button>
            </div>
        </FormControl>
    );
    };

    const handleEditUser = (employee) => {
        console.log('Editing employee:', employee);
        console.log('Employee permissions:', employee.permissions);
        
        // Ensure permissions is an array
        let permissions = employee.permissions || [];
        if (!Array.isArray(permissions)) {
            try {
                permissions = JSON.parse(permissions);
            } catch (e) {
                console.error('Error parsing permissions:', e);
                permissions = [];
            }
        }
        
        console.log('Processed permissions for editing:', permissions);
        
        setEditingUser(employee);
        setEditUserForm({
            fullName: employee.fullName,
            contactNumber: employee.contactNo,
            email: employee.email,
            role: employee.role,
            permissions: permissions
        });
        setOpenUpdate(true);
    };

    const handleEditFormChange = (e) => {
        const { name, value } = e.target;
        setEditUserForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmitEditUser = async () => {
        try {
            const url = getValidUrl(`admin/updateemployee/${editingUser._id}`);
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            // Ensure permissions is correctly formatted as an array of strings
            const permissions = editUserForm.role === 'admin' 
                ? [...permissionNames] // Create a copy to avoid reference issues
                : editUserForm.permissions.map(perm => 
                    typeof perm === 'object' && perm.hasOwnProperty('title') 
                        ? perm.title 
                        : perm
                  );
            
            console.log('Submitting edit with permissions:', permissions); // Debug log

            const data = {
                fullName: editUserForm.fullName,
                contactNo: editUserForm.contactNumber,
                role: editUserForm.role,
                permissions: permissions
            };

            console.log('Sending edit data to server:', data); // Debug log

            // Send data directly without JSON.stringify
            const response = await axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Server response:', response.data); // Debug server response

            if (response.data.Status === "success") {
                toast.success('User updated successfully');
                // Refresh the employee list
                const updatedData = await showData();
                setShowEmployee(updatedData || []);
                // Close the edit modal
                setEditingUser(null);
            } else {
                throw new Error(response.data.message || 'Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
            toast.error(error.message || 'Failed to update user');
        }
    };

    const handleDeleteUser = async (employee) => {
        try {
            // Confirm deletion
            const confirmDelete = window.confirm(`Are you sure you want to delete the user ${employee.fullName}?`);
            if (!confirmDelete) return;

            const url = getValidUrl(`admin/deleteemployee/${employee._id}`);
            if (!url) {
                throw new Error('Invalid API URL configuration');
            }

            const response = await axios.get(url);

            if (response.data.Status === "Success") {
                toast.success('User deleted successfully');
                // Refresh the employee list
                const updatedData = await showData();
                setShowEmployee(updatedData || []);
            } else {
                throw new Error(response.data.message || 'Failed to delete user');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error(error.message || 'Failed to delete user');
        }
    };

    const renderEditUserModal = () => {
        if (!editingUser) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Edit User</h2>
                        <button onClick={() => setEditingUser(null)} className="close-btn">×</button>
                                    </div>
                    <div className="modal-body">
                                            <div className="form-group">
                            <label>Full Name</label>
                            <input
                                type="text"
                                name="fullName"
                                value={editUserForm.fullName}
                                onChange={handleEditFormChange}
                                placeholder="Enter full name"
                                                />
                                            </div>
                        <div className="form-group">
                            <label>Contact Number</label>
                            <input
                                type="text"
                                name="contactNumber"
                                value={editUserForm.contactNumber}
                                onChange={handleEditFormChange}
                                placeholder="Enter contact number"
                            />
                                        </div>
                                            <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={editUserForm.email}
                                onChange={handleEditFormChange}
                                placeholder="Enter email"
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                            <label>Role</label>
                            <select
                                name="role"
                                value={editUserForm.role}
                                onChange={handleEditFormChange}
                            >
                                <option value="admin">Administrator</option>
                                <option value="moderator">Moderator</option>
                            </select>
                                            </div>
                        {editUserForm.role === 'moderator' && (
                                            <div className="form-group">
                                <label>Permissions</label>
                                {renderPermissionGroups()}
                                            </div>
                        )}
                        <div className="modal-actions">
                            <button 
                                onClick={() => setEditingUser(null)} 
                                className="cancel-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                onClick={handleSubmitEditUser} 
                                className="submit-btn"
                                        >
                                Update User
                                        </button>
                                    </div>
                                </div>
                            </div>
                                    </div>
        );
    };

    const mappingEmployees = showEmployee.map((employee) => {
        return <div className="users_data_wrapper col-12 p-0">
            <div className="users_data_table d-flex align-items-center justify-content-between">
                <div className="user_element user_name d-flex">
                    <p>{employee.fullName}</p>
                                    </div>
                <div className="user_element user_designation">
                    <p>{employee.role}</p>
                                </div>
                <div className="user_element ">
                    <p>{employee.email}</p>
                            </div>
                <div className="user_element ">
                    <p>{employee.contactNo}</p>
                </div>
                <div className="user_element user-actions">
                    <button 
                        className="action-button edit-btn" 
                        type="button" 
                        onClick={() => handleEditUser(employee)}
                    >
                        <i className="fa fa-pencil pr-3"></i>
                        <span>Edit</span>
                                        </button>

                    <button 
                        className="action-button delete-btn" 
                        type="button" 
                        onClick={() => handleDelete(employee._id)}
                    >
                        <i className="fa fa-trash"></i>
                        <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
        </div>
    });

    // Helper function to get permission icons
    const getPermissionIcon = (permission) => {
        const iconMap = {
            'Add User': 'fa-user-plus',
            'Edit User Permission': 'fa-edit',
            'Delete User': 'fa-user-times',
            'See all Sell Cars': 'fa-car',
            'See all Buy Cars': 'fa-shopping-cart',
            'Add to Stock': 'fa-plus-circle',
            'Remove from Stock': 'fa-minus-circle',
            'Close Requests': 'fa-times-circle',
            'Reopen Requests': 'fa-refresh',
            'Add Car Models': 'fa-plus',
            'Edit Car Models': 'fa-pencil',
            'Delete Car Models': 'fa-trash',
            'View Analytics': 'fa-line-chart',
            'Export Reports': 'fa-file-excel-o',
            'Manage Favorites': 'fa-heart',
            'View Customer Details': 'fa-address-card'
        };
        return iconMap[permission] || 'fa-cog';
    };

    return (
        <>
            <Toaster position="top-right" />
            {renderEditUserModal()}
            <div className="users-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard_content">
                                <div className="dashboard_content_header d-flex justify-content-end align-items-center">
                                    <button className="action-button" type="button" onClick={handleOpen}>
                                        <i className="fa fa-plus"></i>
                                        <span>Add User</span>
                                    </button>
                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={open}
                                        className={classes.modal}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}
                                    >
                                        <Fade in={open}>
                                            <div className={classes.paper} >
                                                <div className="add_user_div">
                                                    <div className="add_user_header">
                                                        <h2>Add New User</h2>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Full Name</label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="name" 
                                                                    placeholder="Enter user's full name"
                                                                    onChange={handleChangeUserValues}
                                                                    name="fullName" 
                                                                    value={userValue.fullName}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Password</label>
                                                                <input 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="password" 
                                                                    placeholder="Enter Employee's Password"
                                                                    onChange={handleChangeUserValues}
                                                                    name="password" 
                                                                    value={userValue.password}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-2">
                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Contact Number</label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="contactNo" 
                                                                    placeholder="Enter user's contact number"
                                                                    onChange={handleChangeUserValues}
                                                                    name="contactNumber" 
                                                                    value={userValue.contactNumber}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Email Address</label>
                                                                <input 
                                                                    type="email" 
                                                                    className="form-control" 
                                                                    id="email" 
                                                                    placeholder="Enter user's email address"
                                                                    onChange={handleChangeUserValues}
                                                                    name="emailAddress" 
                                                                    value={userValue.emailAddress}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sel-wrapper d-flex flex-column">
                                                        <label htmlFor="lang">Role</label>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                            <Select
                                                                value={selectRole}
                                                                onChange={handleChangeRole}
                                                                displayEmpty
                                                                className="list-dt-4"
                                                            >
                                                                <MenuItem value="">
                                                                    <div className="d-flex flex-column">
                                                                        <p style={{ color: "#000" }}> Please select the User's Role</p>
                                                                    </div>
                                                                </MenuItem>
                                                                <MenuItem value="admin">
                                                                    <div className="d-flex flex-column">
                                                                        <p>Administrator</p>
                                                                        <small>Full access to all features and settings</small>
                                                                    </div>
                                                                </MenuItem>
                                                                <MenuItem value="moderator">
                                                                    <div className="d-flex flex-column">
                                                                        <p>Moderator</p>
                                                                        <small>Limited access based on assigned permissions</small>
                                                                    </div>
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    {selectRole === "moderator" ? (
                                                        <div className="sel-wrapper d-flex flex-column mt-2">
                                                            <label htmlFor="lang">Permissions</label>
                                                            {renderPermissionGroups()}
                                                            <small className="text-muted mt-2">
                                                                Select the permissions for this moderator. They will only have access to the selected features.
                                                            </small>
                                                        </div>
                                                    ) : selectRole === "admin" ? (
                                                        <div className="sel-wrapper d-flex flex-column mt-2">
                                                            <label htmlFor="lang">Permissions</label>
                                                            <div className="alert alert-info">
                                                                <i className="fa fa-info-circle me-2"></i>
                                                                Administrators automatically have access to all features and permissions
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="col button-container mt-1 add_user_button pt-2">
                                                        <button className="btn border border-top-0 p-2 px-3 m-2 cancel-button mx-4 fw-bolder"
                                                            style={{ backgroundColor: "#ffebeb", borderRadius: "0px !important", fontWeight: "bold" }} onClick={handleClose}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button onClick={handleSaveEmployee} className="btn btn-danger border border-0 text-light p-2 px-4 fw-bolder submit-btn">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Users List Section */}
                    <div className="users-list-section">
                        {isLoading ? (
                            <div className="loading-state">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="error-state">
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            </div>
                        ) : showEmployee && showEmployee.length > 0 ? (
                            <div className="users-grid">
                                {showEmployee.map((employee) => (
                                    <div key={employee._id} className="user-card">
                                        <div className="user-card-header">
                                            <div className="user-avatar">
                                                <i className="fa fa-user"></i>
                                            </div>
                                            <div className="user-info">
                                                <h3 className="user-name">{employee.fullName}</h3>
                                                <span className="user-role">{employee.role}</span>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div className="detail-item">
                                                <i className="fa fa-envelope"></i>
                                                <span>{employee.email}</span>
                                            </div>
                                            <div className="detail-item">
                                                <i className="fa fa-phone"></i>
                                                <span>{employee.contactNo}</span>
                                            </div>
                                        </div>
                                        <div className="user-actions">
                                            <button className="edit-btn" onClick={() => handleEditUser(employee)}>
                                                <i className="fa fa-pencil"></i>
                                                <span>Edit</span>
                                            </button>
                                            <button className="delete-btn" onClick={() => handleDeleteUser(employee)}>
                                                <i className="fa fa-trash"></i>
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="empty-state">
                                <img src="/assets/dashboard_images/no-data.svg" alt="No users" />
                                <h3>No Users Found</h3>
                                <p>Start by adding your first user</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{`
                .users-area {
                    min-height: 100vh;
                    background-color: #f8f9fa;
                    padding: 2rem 0;
                }

                .sel-wrapper {
                    margin-bottom: 1.5rem;
                }

                .sel-wrapper label {
                    color: #666;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                :global(.MuiSelect-select) {
                    max-height: 300px !important;
                    overflow-y: auto !important;
                }

                :global(.MuiMenuItem-root.Mui-disabled) {
                    opacity: 1 !important;
                    color: #333 !important;
                    font-weight: 600 !important;
                    background-color: #f8f9fa !important;
                    pointer-events: none !important;
                }

                :global(.MuiMenuItem-root) {
                    display: flex !important;
                    align-items: center !important;
                    gap: 8px !important;
                }

                :global(.MuiMenuItem-root i) {
                    width: 20px !important;
                    text-align: center !important;
                }

                :global(.MuiMenuItem-root.Mui-selected) {
                    background-color: rgba(217, 83, 79, 0.08) !important;
                }

                :global(.MuiMenuItem-root.Mui-selected:hover) {
                    background-color: rgba(217, 83, 79, 0.12) !important;
                }

                :global(.btn-outline-secondary:hover),
                :global(.btn-outline-primary:hover) {
                    background-color: #d9534f !important;
                    border-color: #d9534f !important;
                    color: white !important;
                }

                .text-muted {
                    color: #6c757d !important;
                    font-size: 0.875rem;
                }

                .gap-2 {
                    gap: 0.5rem !important;
                }

                .mt-2 {
                    margin-top: 0.5rem !important;
                }
            `}</style>
        </>
    );
}

export default UsersArea;