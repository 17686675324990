/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import "../buy/Modal.css";

// Start of Modal code 

export default function SellModal(props) {
  const { value, selectCar, fun, years } = props;
  const [open, setOpen] = React.useState(false);
  const [carsData, setCarsData] = React.useState({ makes: [], model: [], version: [] });
  const [currentStep, setCurrentStep] = useState(0);

  const handleOpen = () => {
    setOpen(true);
    setCurrentStep(0); // Reset step on open
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange6 = (value, name) => {
    fun(value, name);
  };

  useEffect(() => {
    let makes = [];
    value.forEach((item) => {
      if (makes.findIndex(m => item.make.toUpperCase() === m.make) === -1) {
        makes.push({ make: item.make.toUpperCase(), to: item.year.to, from: item.year.from });
      } else {
        let index = makes.findIndex(m => item.make.toUpperCase() === m.make);
        if (makes[index].to < item.year.to) makes[index].to = item.year.to;
        if (makes[index].from > item.year.from) makes[index].from = item.year.from;
      }
    });

    let model = [];
    value.forEach((item) => {
      if (model.findIndex(m => item.model === m.model) === -1) {
        model.push({ model: item.model, to: item.year.to, from: item.year.from, make: item.make.toUpperCase() });
      } else {
        let index = model.findIndex(m => item.model === m.model);
        if (model[index].to < item.year.to) model[index].to = item.year.to;
        if (model[index].from > item.year.from) model[index].from = item.year.from;
      }
    });

    let version = [];
    value.forEach((item) => {
      if (version.findIndex(m => item.version === m.version) === -1) {
        version.push({ version: item.version, to: item.year.to, from: item.year.from, make: item.make.toUpperCase(), model: item.model });
      } else {
        let index = version.findIndex(m => item.version === m.version);
        if (version[index].to < item.year.to) version[index].to = item.year.to;
        if (version[index].from > item.year.from) version[index].from = item.year.from;
      }
    });
    setCarsData({ makes, model, version });
  }, [value]);

  // Displaying year div content
  const [search, setSearch] = useState(years);
  const handleChangeYear = (e) => {
    setSearch(e.target.value);
  };

  const yearsArr = years.filter(num =>
    num.toString().startsWith(search)
  );

  const mappingYear = yearsArr.map((car, index) => (
    <div 
      key={`year-${car}-${index}`}
      className="p-2 list" 
      lid="year" 
      style={selectCar.year === car ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} 
      onClick={() => handleChange6(car, "year")}
    >
      <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
        {selectCar.year === car ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
        <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car}</h5>
      </div>
    </div>
  ));

  // Displaying Make div content 
  const [searchMake, setSearchMake] = useState("");

  const handleChangeMake = (e) => {
    setSearchMake(e.target.value);
  };

  const mappingMake = carsData.makes.map((car, index) => {
    if (car.make.substring(0, searchMake.length).toUpperCase() === searchMake.toUpperCase()) {
      return (
        car ? Number(car.to) >= selectCar.year && Number(car.from) <= selectCar.year ?
          <div 
            key={`make-${car.make}-${index}`}
            className="p-2 list" 
            lid="year" 
            style={selectCar.make === car.make ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} 
            onClick={() => handleChange6(car.make, "make")}
            id={car.make}
          >
            <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
              {selectCar.make === car.make ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
              <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.make}</h5>
            </div>
          </div> : null : null
      );
    }
    return null;
  });

  // Displaying Model div content 
  const [searchModel, setSearchModel] = useState("");

  const handleChangeModel = (e) => {
    setSearchModel(e.target.value);
  };

  const mappingModel = carsData.model.map((car, index) => {
    if (car.model.substring(0, searchModel.length).toUpperCase() === searchModel.toUpperCase()) {
      return (
        car ? car.make === selectCar.make && Number(car.to) >= selectCar.year && Number(car.from) <= selectCar.year ?
          <div 
            key={`model-${car.model}-${index}`}
            className="p-2 list" 
            lid="year" 
            style={selectCar.model === car.model ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} 
            onClick={() => handleChange6(car.model, "model")}
            id={car.model}
          >
            <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
              {selectCar.model === car.model ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
              <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.model}</h5>
            </div>
          </div> : null : null
      );
    }
    return null;
  });

  // Displaying Version div content 
  const [searchVersion, setSearchVersion] = useState("");

  const handleChangeVersion = (e) => {
    setSearchVersion(e.target.value);
  };

  const mappingVersion = carsData.version.map((car, index) => {
    if (car.version.substring(0, searchVersion.length).toUpperCase() === searchVersion.toUpperCase()) {
      return (
        car ? car.model === selectCar.model && car.make === selectCar.make && Number(car.to) >= selectCar.year && Number(car.from) <= selectCar.year ?
          <div 
            key={`version-${car.version}-${index}`}
            className="p-2 list" 
            lid="version" 
            style={selectCar.version === car.version ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} 
            onClick={() => handleChange6(car.version, "version")}
          >
            <div className="d-flex" style={{ width: "300px", paddingLeft: "20px" }}>
              {selectCar.version === car.version ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" /> : null}
              <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.version}</h5>
            </div>
          </div> : null : null
      );
    }
    return null;
  });

  return (
    <div>
      <Button variant="contained" color="primary" style={{ boxShadow: "none", float: "left", display: "flex", justifyContent: "left", fontFamily: " 'poppins', sans-serif !important" }} onClick={handleOpen}>
        {selectCar.year + ", " + selectCar.make + ", " + selectCar.model + ", " + selectCar.version}
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="modal-container">
          <div className="container modal-subcontainer">
            <div className="row" id="rowArea">
              {/* Year Selection */}
              {currentStep === 0 && (
                <div className="col option border border-right-1" style={selectCar.year !== 'year' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption1">
                  <h5 className="mt-4 mb-3 fw-bold">Select Year</h5>
                  <div className="search border border-1 mb-3" style={{ height: "45px", width: "100%", borderRadius: "5px" }}>
                    <input className="w-100 border-0" type="text" placeholder="Search Year" value={search} onChange={handleChangeYear} />
                  </div>
                  {mappingYear}
                </div>
              )}
              {/* Make Selection */}
              {currentStep === 1 && (
                <div className="col option border border-right-1" style={selectCar.make !== 'make' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption2">
                  <h5 className="mt-4 mb-3 fw-bold">Select Make</h5>
                  <div className="search border border-1 mb-3" style={{ height: "45px", width: "100%", borderRadius: "5px" }}>
                    <input className="w-100 border-0" type="text" placeholder="Search Make" value={searchMake} onChange={handleChangeMake} />
                  </div>
                  {mappingMake}
                </div>
              )}
              {/* Model Selection */}
              {currentStep === 2 && (
                <div className="col option border border-right-1" style={selectCar.model !== 'model' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption3">
                  <h5 className="mt-4 mb-3 fw-bold">Select Model</h5>
                  <div className="search border border-1 mb-3" style={{ height: "45px", width: "100%", borderRadius: "5px" }}>
                    <input className="w-100 border-0" type="text" placeholder="Search Model" value={searchModel} onChange={handleChangeModel} />
                  </div>
                  {mappingModel}
                </div>
              )}
              {/* Version Selection */}
              {currentStep === 3 && (
                <div className="col option border border-right-1" style={selectCar.version !== 'version' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption4">
                  <h5 className="mt-4 mb-3 fw-bold">Select Version</h5>
                  <div className="search border border-1 mb-3" style={{ height: "45px", width: "100%", borderRadius: "5px" }}>
                    <input className="w-100 border-0" type="text" placeholder="Search Version" value={searchVersion} onChange={handleChangeVersion} />
                  </div>
                  {mappingVersion}
                </div>
              )}
            </div>
            <div className="modal-footer" style={{ marginTop: '20px' }}>
              <Button
                onClick={() => {
                  if (currentStep > 0) {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                style={{ marginRight: '10px' }}
                disabled={currentStep === 0}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  if (currentStep < 3) {
                    setCurrentStep(currentStep + 1);
                  } else {
                    handleClose();
                  }
                }}
              >
                {currentStep < 3 ? "Next" : "Done"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
