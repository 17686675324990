/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./sell_style.css";
import "../../App.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SellModal from "./SellModal";

import TextField from "@material-ui/core/TextField";
import SellSubmit from "./SellSubmit";
import CropImages from "./Cropper";
import { useGlobalContext } from "../../context/DataContext";
import axios from "axios";
import { Tooltip } from "@material-ui/core";
import ErrorMessage from "../Other/ErrorMessage";
import { Link } from "react-router-dom";
import { API_ENDPOINT, getValidApiUrl } from '../../config/config';
const numWords = require("num-words");

const filter = createFilterOptions();

const SellCar = () => {
  // Handle Dropdown Selection
  const [selectOption, setSelectOption] = React.useState(null);
  const [selectExt, setSelectExt] = React.useState(null);
  const [selectTrans, setSelectTrans] = React.useState("");
  const [selectPriority, setSelectPriority] = React.useState("");
  const [selectAvailable, setSelectAvailable] = React.useState("");
  const [availableDays, setAvailableDays] = React.useState(null);
  // const [selectFrom, setSelectFrom] = React.useState('');
  // const [selectTo, setSelectTo] = React.useState('');
  const [timeField, setTimeField] = React.useState({ from: "", to: "" });
  const [mileage, setMileage] = React.useState();
  const [selectedDays, setDays] = React.useState([
    { days: [], timeField: { from: "", to: "" } },
  ]);

  const [amountInWords, setAmountInWords] = useState("");

  const {
    handleNext,
    setHandleNext,
    handleNext2,
    setHandleNext2,
    divHandle,
    setDivHandle,
  } = useGlobalContext();

  const [error, setError] = useState(false);

  const availableselectExts = [
    { title: "Red" },
    { title: "Green" },
    { title: "Yellow" },
    { title: "Blue" },
    { title: "Black" },
    { title: "White" },
    { title: "Silver" },
    { title: "Gray" },
    { title: "Brown" },
    { title: "Orange" },
    { title: "Pink" },
    { title: "Purple" },
    { title: "Gold" },
    { title: "Beige" },
    { title: "Maroon" },
    { title: "Mint" },
    { title: "Navy" },
    { title: "Lavender" },
    { title: "Turquoise" },
    { title: "Copper" },
    { title: "Champagne" },
    { title: "Indigo" },
    { title: "Tan" },
    { title: "Burgundy" },
    { title: "Plum" },
    { title: "Khaki" },
    { title: "Aquamarine" },
    { title: "Lime" },
    { title: "Coral" },
    { title: "Teal" },
    { title: "Lilac" },
    { title: "Fuchsia" },
    { title: "Wine" },
    { title: "Army" },
    { title: "Lemon" },
    { title: "Olive" },
    { title: "Apricot" },
    { title: "Mauve" },
    { title: "Taupe" },
    { title: "Mint" },
    { title: "Crimson" },
    { title: "Ivory" },
    { title: "Charcoal" },
    { title: "Cyan" },
    { title: "Azure" }
  ].sort((a, b) => a.title.localeCompare(b.title));

  const availableCities = [
    { title: "Unregistered", isSpecial: true },
    { title: "Province", isHeader: true },
    { title: "Punjab" },
    { title: "Sindh" },
    { title: "KPK" },
    { title: "Balochistan" },
    { title: "Punjab", isHeader: true },
    { title: "Lahore" },
    { title: "Faisalabad" },
    { title: "Rawalpindi" },
    { title: "Multan" },
    { title: "Gujranwala" },
    { title: "Sialkot" },
    { title: "Sheikhupura" },
    { title: "Sargodha" },
    { title: "Sahiwal" },
    { title: "Bahawalpur" },
    { title: "Gujrat" },
    { title: "Jhang" },
    { title: "Kasur" },
    { title: "Okara" },
    { title: "Rahim Yar Khan" },
    { title: "Chiniot" },
    { title: "Dera Ghazi Khan" },
    { title: "Khanewal" },
    { title: "Hafizabad" },
    { title: "Mandi Bahauddin" },
    { title: "Jhelum" },
    { title: "Sindh", isHeader: true },
    { title: "Karachi" },
    { title: "Hyderabad" },
    { title: "Sukkur" },
    { title: "Larkana" },
    { title: "Mirpur Khas" },
    { title: "Nawabshah" },
    { title: "Jacobabad" },
    { title: "Shikarpur" },
    { title: "Dadu" },
    { title: "Thatta" },
    { title: "KPK", isHeader: true },
    { title: "Peshawar" },
    { title: "Mardan" },
    { title: "Mingora" },
    { title: "Kohat" },
    { title: "Dera Ismail Khan" },
    { title: "Abbottabad" },
    { title: "Bannu" },
    { title: "Swabi" },
    { title: "Nowshera" },
    { title: "Charsadda" },
    { title: "Balochistan", isHeader: true },
    { title: "Quetta" },
    { title: "Turbat" },
    { title: "Khuzdar" },
    { title: "Gwadar" },
    { title: "Hub" },
    { title: "Chaman" },
    { title: "Pishin" },
    { title: "Zhob" },
    { title: "Loralai" },
    { title: "Sibi" },
    { title: "Gilgit Baltistan", isHeader: true },
    { title: "Gilgit" },
    { title: "Skardu" },
    { title: "Hunza" },
    { title: "Ghizer" },
    { title: "Azad Kashmir", isHeader: true },
    { title: "Muzaffarabad" },
    { title: "Mirpur" },
    { title: "Kotli" },
    { title: "Rawalakot" },
    { title: "Bagh" },
    { title: "Federal Territory", isHeader: true },
    { title: "Islamabad" }
  ];

  const handleClick1 = () => {
    setHandleNext(true);
    setHandleNext2(true);
  };
  const handleClick2 = () => {
    setHandleNext2(true);
  };

  //  numWords(mileage);

  const [sendData, setSendData] = useState({});
  //set days

  const handleDays = (type, iftime, val, index) => {
    let days = selectedDays;
    let availDays = availableDays;

    if (type === "time") {
      days[index].timeField = {
        ...days[index].timeField,
        [iftime]: val.target.value,
      };
    } else if (type === "day") {
      let findindex = days[index].days.indexOf(val);
      if (findindex === -1) days[index].days.push(val);
      else days[index].days.splice(findindex, 1);
      let findindex2 = availDays.indexOf(val);
      if (findindex2 === -1) availDays.push(val);
      else availDays.splice(findindex2, 1);
    }

    setDays([...days]);
    setAvailableDays([...availDays]);
    // .log(selectedDays);
  };

  // Handle Change Mileage Div
  const handleChangeMileageDiv = (e) => {
    setMileage(e.target.value);
    setAmountInWords(numWords(e.target.value));
    setTimeout(() => {
      setAmountInWords("");
    }, 3000);
  };

  // Contact State
  const [contact, setContact] = React.useState({
    name: "",
    email: "",
    number: "",
    city: "",
    address: "",
    notes: "",
  });
  // cropper model states
  const [openCropper, setOpenCropper] = React.useState(-1);
  // Start of contact page
  const handleContact = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContact({ ...contact, [name]: value });
  };

  // Use location code
  const [selectLocation, setSelectLocation] = React.useState(false);
  const openLocationMenu = () => {
    setSelectLocation(true);
  };

  //get available cars from database
  const getAvailableCars = async () => {
    try {
        const url = getValidApiUrl('cars/availablecars');
        if (!url) {
            throw new Error('Invalid API URL');
        }
        const response = await axios.get(url);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching available cars:', error);
        throw error;
    }
  };

  // console.log(process.env.REACT_APP_API_URL);

  const [availableYears, setAvailableYears] = useState([]);

  useEffect(() => {
    let years = function (startYear) {
      var currentYear = new Date().getFullYear(),
        years = [];
      startYear = startYear || 1940;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      years.sort(function (a, b) {
        return b - a;
      });
      return years;
    };
    setAvailableYears(years(1940));
  }, []);

  const [availableCar, setAvailableCar] = React.useState([]);
  const [selectCar, setSelectCar] = React.useState({
    year: "year",
    make: "make",
    model: "model",
    version: "version",
  });

  //  Handle Upload Pics
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleChangeImage = (event) => {
    const files = event.target.files;
    let imageFiles = [...selectedFiles];
    for (let i = 0; i < files.length; i++) {
        imageFiles.push(files[i]);
    }
    setSelectedFiles(imageFiles);
    readImages(imageFiles);
  };

  const readImages = async (imageFiles) => {
    try {
      const promises = imageFiles.map(file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.readAsDataURL(file);
      }));
      const imagesData = await Promise.all(promises);
      setPreview(imagesData);
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   if (selectedFiles.length === 0) {
  //     setPreview([]);
  //     return;
  //   }
  //   // setBlobs(selectedFiles);
  //   // setPreview([...preview, selectedFiles])
  //   console.log(selectedFiles)

  //   let p= [...preview];
  //   selectedFiles.map((file) => {
  //     console.log(file)
  //   async function handleFileRead(e) {
  //     const binaryData = fileReader.result;
  //     const base64Data = binaryData;
  //     const objectUrl = base64Data;

  //     // setPreview([...preview, objectUrl]);
  //     p.push(objectUrl);
  //   }
  //   let fileReader = new FileReader();
  //    fileReader.readAsDataURL(file);
  //   fileReader.onload = handleFileRead;
  //   });

  //   setPreview(p);
  // }, [selectedFiles]);

  


  const [blobs, setBlobs] = useState();
  function base64ToBlob(base64, mime) {
    mime = mime || "";
    var sliceSize = 1024;
    var byteChars = window.atob(base64.toString());
    var byteArrays = [];

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }
  useEffect(() => {
    if (preview.length > 0) {
      console.log(preview[0]);
      //console.log(base64ToBlob(preview[0]));
      //window.open(URL.createObjectURL(new Blob([preview[0]])))
      //  setBlobs(new File([preview[0]], "image.jpg", { type: "image/jpeg" }))
    }
  }, [preview]);
  
  useEffect(() => {
    setAvailableDays(["mon", "tue", "wed", "thu", "fri", "sat"]);
    getAvailableCars().then((res) => {
      setAvailableCar(res);
    });
  }, []);


  const handleDelete = (i) => {
    let p = preview;
    let s = selectedFiles;
    s.splice(i, 1);
    setSelectedFiles([...s]);
    p.splice(i, 1);
    setPreview([...p]);
  };

  // End of handle upload pics

  // const handleChange = (event) => { setSelectOption(event.target.value) };
  // const handleChange2 = (event) => { setSelectExt(event.target.value) };
  const handleChange3 = (event) => {
    setSelectTrans(event.target.value);
  };
  const handleChange4 = (event) => {
    setSelectPriority(event.target.value);
  };
  const handleChange5 = (event) => {
    setSelectAvailable(event.target.value);
  };
  // const handleChangeFrom = (event) => { setSelectFrom(event.target.value) };
  // const handleChangeTo = (event) => { setSelectTo(event.target.value) };

  const handleChange6 = (value, name) => {
    if (name === "year") {
      setSelectCar({
        ...selectCar,
        [name]: value,
        make: "make",
        model: "model",
        version: "version",
      });
    } else if (name === "make") {
      setSelectCar({
        ...selectCar,
        [name]: value,
        model: "model",
        version: "version",
      });
    } else if (name === "model") {
      setSelectCar({ ...selectCar, [name]: value, version: "version" });
    } else setSelectCar({ ...selectCar, [name]: value });
  };

  // set week day code start
  const [selectWeekDay, setSelectWeekDay] = React.useState(false);

  const weekDisappear = () => {
    setSelectWeekDay(true);
  };

  // Filter Color code
  const changeFilter = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setSelectExt(newValue.inputValue);
    } else {
      setSelectExt(newValue.title);
    }
  };

  // Filter City code
  const changeFilterCity = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setSelectOption(newValue.inputValue);
    } else {
      setSelectOption(newValue.title);
    }
  };

  // Add new state for mileage unit
  const [mileageUnit, setMileageUnit] = React.useState("km");

  // Send Data to review Request

  const handleNewDiv = () => {
    if (
      contact.name === "" ||
      contact.number === "" ||
      contact.address === "" ||
      contact.city === ""
    ) {
      setError(true);
      setErrorData("Please fill all the fields");
      return;
    }
    setDivHandle(true);
    setSendData({
      selectOption: selectOption,
      selectExt: selectExt,
      selectTrans: selectTrans,
      selectPriority: selectPriority,
      selectAvailable: selectAvailable,
      availableDays: selectedDays,
      mileage: mileage,
      mileageUnit: mileageUnit,
      selectCar: selectCar,
      contact: contact,
      images: selectedFiles,
    });
  };



  

  const [inspection, setInspection] = React.useState(false);

  // Handle the next click function

  const [errorData, setErrorData] = React.useState("");

  const handle_next = () => {
    if (
      selectOption === null ||
      selectExt === null ||
      selectTrans === "" ||
      mileage === ""
    ) {
      setError(true);
      setErrorData("Please fill all the fields");
      return;
    }
    setHandleNext(false);
  };

  const [addPhotos, setAddPhotos] = React.useState(false);

  const handle_next2 = () => {
    if(selectedFiles.length < 3){
      setError(true);
      setErrorData("Please add at least 3 photos");
      return;
    }
    if (
      selectPriority === "" ||
      selectAvailable === "" ||
      availableDays === null
    ) {
      setErrorData("Please fill all the fields");
      setError(true);
      return;
    }
    setHandleNext(false);
    setHandleNext2(false);
  };

  // show element on media query
  const [showBottomText, setShowBottomText] = React.useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowBottomText(true);
    }
  }, []);

  return (
    <>
      {/* Start of Sell Car  */}

      <ErrorMessage open={error} setOpen={setError} data={errorData} />

      <div className="nav-wrapper sell_your_car">
        <div className="image-wrapper">
          <Link to="/">
            <img src="assets/images/logo.png" width="222px" alt="" />
          </Link>
        </div>
        <div className="text-wrapper">
          <h1
            style={{
              fontSize: "24px !important",
              paddingLeft: "16rem",
              paddingTop: "20px",
              fontWeight: 700,
            }}
          >
            Sell your car in three easy steps
          </h1>
        </div>
      </div>
      <div className="progress-steps-container">
        <div className="progress-steps">
          <div className={`progress-step ${handleNext ? 'active' : ''}`} onClick={handleClick1}>
            <div className="step-number">1</div>
            <div className="step-text">Car Information</div>
          </div>
          <div className={`progress-step ${!handleNext && handleNext2 ? 'active' : ''}`} onClick={handleClick2}>
            <div className="step-number">2</div>
            <div className="step-text">Upload Photos & Set Priority</div>
          </div>
          <div className={`progress-step ${!handleNext2 ? 'active' : ''}`}>
            <div className="step-number">3</div>
            <div className="step-text">Contact Information</div>
          </div>
        </div>
      </div>
      <div
        className="position-relative"
        style={{
          minHeight: "88vh",
          height: "auto",
        }}
      >
        {divHandle === false ? (
          <div className="container-fluid" id="sellyourcar">
            <div className="row justify-content-center mt-0">
              <div className="col-11 col-sm-9 col-md-7 col-lg-12 text-center p-0 mb-2">
                <div className="px-0 pt-4 pb-0 mt-1">
                  {/* <!-- <h2><strong>Sign Up Your User Account</strong></h2>
                    <p>Fill all form field to go to next step</p> --> */}
                  <div className="row sell-a-car m-0">
                    <div className="col-lg-12 col-sm-12 mx-0">
                      <div id="msform">
                        {/* <!-- fieldsets --> */}
                        {handleNext ? (
                          <fieldset id="fieldset-1">
                            <div className="form-card container">
                              <p>STEP 1</p>
                              <h3 className="fs-title">
                                Enter your car information
                              </h3>
                              <div
                                className="row d-flex sell_a_car_row"
                                style={{ marginBottom: "60px" }}
                              >
                                <div
                                  className="col-lg-8 col-sm-12 make_button"
                                  style={{ width: "620px" }}
                                >
                                  <div className="">
                                    <label className="pay">
                                      Make
                                      <span className="text-warning">*</span>
                                    </label>
                                    <SellModal
                                      fun={handleChange6}
                                      value={availableCar}
                                      selectCar={selectCar}
                                      years={availableYears}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 registration_city_div">
                                  <div className="">
                                    <label className="pay">
                                      Registration City
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>

                                  <Tooltip
                                    title="Select your vehicle's registration city"
                                    placement="top-start"
                                  >
                                    <div className="form-group">
                                      <Autocomplete
                                        value={selectOption}
                                        onChange={changeFilterCity}
                                        options={availableCities}
                                        getOptionLabel={(option) => {
                                          if (typeof option === "string") {
                                            return option;
                                          }
                                          return option.title || "";
                                        }}
                                        renderOption={(option) => (
                                          <div style={{ 
                                            padding: option.isHeader ? "12px 16px 8px" : "8px 16px 8px 32px",
                                            backgroundColor: option.isHeader ? "#f5f5f5" : "transparent",
                                            fontWeight: option.isHeader || option.isSpecial ? 600 : 400,
                                            color: option.isHeader ? "#666" : "#333",
                                            pointerEvents: option.isHeader ? "none" : "auto",
                                            borderBottom: option.isHeader ? "1px solid #ddd" : "none"
                                          }}>
                                            {option.title}
                                          </div>
                                        )}
                                        style={{ width: 323 }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select City"
                                            variant="outlined"
                                          />
                                        )}
                                        groupBy={(option) => {
                                          if (option.isHeader || option.isSpecial) return "";
                                          // Find the previous header
                                          const index = availableCities.findIndex(city => city.title === option.title);
                                          for (let i = index; i >= 0; i--) {
                                            if (availableCities[i].isHeader) {
                                              return availableCities[i].title;
                                            }
                                          }
                                          return "";
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="d-flex sell-a-car_bottom row ml-1">
                                <div className="col-lg-3 col-sm-12 col-3">
                                  <div className="">
                                    <label className="pay">
                                      Exterior Color
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>

                                  <Tooltip
                                    title="Select your vehicle's exterior color"
                                    placement="top-start"
                                  >
                                    <Autocomplete
                                      value={selectExt}
                                      onChange={changeFilter}
                                      options={availableselectExts}
                                      getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                          return option;
                                        }
                                        return option.title || "";
                                      }}
                                      renderOption={(option) => (
                                        <div style={{ 
                                          padding: "8px 16px",
                                          display: "flex",
                                          alignItems: "center"
                                        }}>
                                          <div style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                            backgroundColor: option.title.toLowerCase(),
                                            marginRight: "10px",
                                            border: "1px solid #ddd"
                                          }} />
                                          {option.title}
                                        </div>
                                      )}
                                      style={{ width: 323 }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Color"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Tooltip>
                                </div>

                                <div
                                  className="col-lg-3 col-sm-12 col-3"
                                  style={{ marginRight: "12px" }}
                                >
                                  <div className="">
                                    <label className="pay">
                                      Transmission
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                      value={selectTrans}
                                      onChange={handleChange3}
                                      displayEmpty
                                      className="list-dt-4"
                                    >
                                      <MenuItem value="">
                                        Select Transmission
                                      </MenuItem>
                                      <MenuItem value="automatic">
                                        Automatic
                                      </MenuItem>
                                      <MenuItem value={"manual"}>
                                        Manual
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>

                                <div
                                  className="mileage_div_margin"
                                  style={{ marginLeft: "43px" }}
                                >
                                  <div className="">
                                    <label className="pay">
                                      MILEAGE
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>

                                  <div className="form-group d-flex mr-2">
                                    <Select
                                      value={mileageUnit}
                                      onChange={(e) => setMileageUnit(e.target.value)}
                                      className="unit-select"
                                      style={{
                                        height: "40px",
                                        marginRight: "10px",
                                        background: "#f5f6f7",
                                        border: "none",
                                        borderRadius: "4px",
                                        padding: "0 10px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "rgba(0, 0, 0, 0.658)",
                                      }}
                                    >
                                      <MenuItem value="km">km</MenuItem>
                                      <MenuItem value="miles">miles</MenuItem>
                                    </Select>
                                    <input
                                      type="text"
                                      style={{
                                        border: 0,
                                        padding: "10px 10px 10px 20px",
                                        background: "#f5f6f7 0% 0% no-repeat padding-box",
                                        marginRight: "5px",
                                        height: "40px",
                                        width: "280px",
                                        fontSize: "16px",
                                      }}
                                      id="mileage"
                                      placeholder={`Enter Mileage in ${mileageUnit}`}
                                      value={mileage}
                                      onChange={handleChangeMileageDiv}
                                    />
                                  </div>
                                  {amountInWords.length > 0 ? (
                                    <div className="guess_number">
                                      <span>{amountInWords} {mileageUnit}</span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <input
                              type="button"
                              name="next"
                              className="next action-button pos-4 bg-gray"
                              value="Save & Continue"
                              onClick={handle_next}
                              style={{ zIndex: 1000 }}
                            />
                          </fieldset>
                        ) : null}

                        {handleNext2 ? (
                          <fieldset id="fieldset-2">
                            <div className="form-card container">
                              {/* <button onClick={handleimageupload}>click me</button> */}
                              <p>STEP 2</p>
                              <h2 className="fs-title">Upload Photos</h2>

                              <div
                                className="row wrapper-2 d-flex"
                                style={{
                                  border: "1px dashed black",
                                  padding: "20px 0px",
                                  height: "auto",
                                }}
                              >
                                <div className="col-lg-8  logo-1 d-flex">
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-6 d-flex justify-content-between">
                                      <img
                                        src="assets/images/Group 2007.svg"
                                        className="img"
                                        alt=""
                                      />
                                      <p className="logo-text">
                                        Add clear Front, Back and Interior
                                        pictures of your car.
                                      </p>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 d-flex justify-content-between">
                                      <img
                                        src="assets/images/Group 2007.svg"
                                        className="img"
                                        alt=""
                                      />
                                      <p className="logo-text">
                                        Photos should be in "jpeg, jpg, png,
                                        gif" format only.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-4 col-sm-12 position-relative ">
                                  <div className="upload-btn">
                                    {preview.length === 15 ? (
                                      <p>You can only upload 15 images</p>
                                    ) : (
                                      <label className="btn btn-default">
                                        <img
                                          src="assets/images/gallery-64.png"
                                          width="18.98px%"
                                          alt=""
                                        />
                                        Add Photos
                                        <input
                                          type="file"
                                          id="carFile"
                                          multiple accept="image/*"
                                          hidden
                                          onChange={handleChangeImage}
                                        />
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* To show the images */}
                              {preview.length === 0 ? null : (
                                <div
                                  className="row wrapper-image-box d-flex p-1 mb-3"
                                  style={{ height: "125px !important" }}
                                >
                                  {preview.length === 0
                                    ? null
                                    : preview.map((p, i = 0) => {
                                        return (
                                          <div className="position-relative wrapper_images_design">
                                            <img
                                              alt="no no no"
                                              src={p}
                                              key={i}
                                              style={{
                                                width: "120px",
                                                height: "120px",
                                                margin: "10px",
                                                marginRight: "5px",
                                                border: "2px solid #dfb6b6",
                                                boxShadow:
                                                  "0px 4px 24px #cdd0e28b",
                                              }}
                                            />

                                            {/* Edit button  */}
                                            {/* <button
                                              onClick={() => setOpenCropper(i)}
                                              type="button"
                                              className=" edit-btn position-absolute"
                                            >
                                              <i className="fa fa-pencil"></i>{" "}
                                              Edit
                                            </button> */}
                                            <button
                                              type="button"
                                              onClick={() => handleDelete(i)}
                                              className="btn trash-btn position-absolute"
                                            >
                                              <i className="fa fa-trash"></i>
                                            </button>
                                          </div>
                                        );
                                      })}
                                  {openCropper >= 0 ? (
                                    <CropImages
                                      closeCropper={setOpenCropper}
                                      setPreview={setPreview}
                                      preview={preview}
                                      selected={URL.createObjectURL(
                                        selectedFiles[openCropper]
                                      )}
                                      index={openCropper}
                                    />
                                  ) : null}
                                </div>
                              )}

                              <div className="wrap-pad">
                                <h6
                                  style={{
                                    fontSize: "22px",
                                    color: "#000000",
                                    marginBottom: "28px",
                                    fontWeight: "500",
                                    textAlign: "left",
                                  }}
                                >
                                  Set priority and availability
                                </h6>

                                <div className="wrapper">
                                  <div className="">
                                    <label className="pay">
                                      PRIORITY
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                      value={selectPriority}
                                      onChange={handleChange4}
                                      displayEmpty
                                      className="list-dt-6"
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <MenuItem
                                        value=""
                                        className="menu_item_div"
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Please Select your priorty{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                      </MenuItem>
                                      <MenuItem value="Instant Offer : I am looking for urgent one day each offer">
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Instant Offer{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>{" "}
                                        <p>
                                          I am looking for urgent one day each
                                          offer{" "}
                                        </p>
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Reasonable price : I'll wait for something reasonable"
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Reasonable Price{" "}
                                        </span>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>{" "}
                                        <p>
                                          {" "}
                                          I'll wait for something reasonable{" "}
                                        </p>
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Highest Price : I am not in a rush, looking for best price"
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Highest Price{" "}
                                        </span>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>{" "}
                                        <p>
                                          {" "}
                                          i am not in a rush, looking for best
                                          price{" "}
                                        </p>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>

                                  <div
                                    className=""
                                    style={{ marginTop: "20px" }}
                                  >
                                    <label className="pay ">
                                      Availability
                                      <span className="text-warning">*</span>
                                    </label>
                                  </div>
                                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                      value={selectAvailable}
                                      onChange={handleChange5}
                                      displayEmpty
                                      className="list-dt-5"
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <MenuItem value="">
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Please Select your availability{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                      </MenuItem>
                                      <MenuItem
                                        value="I'll park for the Best Offer: I am looking for urgent one day each offer "
                                        style={{ fontSize: "13px !important" }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          I'll park for the Best Offer{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>{" "}
                                        <p>
                                          I am looking for urgent one day each
                                          offer
                                        </p>
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Available on Call : I'm available on the call"
                                        }
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                          onClick={() => {
                                            setSelectLocation(false);
                                          }}
                                        >
                                          Available on Call
                                        </span>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>{" "}
                                        <p>I'm available on the call</p>
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          "Use my location : Use my location for the offer"
                                        }
                                        onClick={openLocationMenu}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Use my location{" "}
                                        </span>{" "}
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                        <>&nbsp;</>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              {selectedDays.map((day, i = 0) => {
                                return (
                                  <div
                                    className="d-flex set_availability"
                                    key={i}
                                  >
                                    {selectLocation ? (
                                      <>
                                        <div className="flex-1">
                                          <div
                                            className=""
                                            style={{ marginTop: "10px" }}
                                          >
                                            <label className="pay">
                                              Select Available Time
                                              <span className="text-warning">
                                                *
                                              </span>
                                            </label>
                                          </div>

                                          <div className="">
                                            <div className="d-flex time_divs">
                                              <div className="position-relative sell_from">
                                                <p className="sell-para-22">
                                                  From
                                                </p>

                                                <FormControl
                                                  sx={{ m: 1, minWidth: 120 }}
                                                  className="time_div"
                                                >
                                                  <form noValidate>
                                                    <TextField
                                                      id="time"
                                                      type="time"
                                                      value={
                                                        selectedDays[i]
                                                          .timeField.from
                                                      }
                                                      onChange={(e) =>
                                                        handleDays(
                                                          "time",
                                                          "from",
                                                          e,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  </form>
                                                </FormControl>
                                              </div>

                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                <p className="text-para-33">
                                                  To
                                                </p>

                                                <FormControl
                                                  sx={{ m: 1, minWidth: 120 }}
                                                  className="time_div"
                                                >
                                                  <form noValidate>
                                                    <TextField
                                                      id="time"
                                                      type="time"
                                                      value={
                                                        selectedDays[i]
                                                          .timeField.to
                                                      }
                                                      onChange={(e) =>
                                                        handleDays(
                                                          "time",
                                                          "to",
                                                          e,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  </form>
                                                </FormControl>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="wrapper-days">
                                          {[
                                            "mon",
                                            "tue",
                                            "wed",
                                            "thu",
                                            "fri",
                                            "sat",
                                            "sun",
                                          ].map((day, j) => {
                                            if (i > 6) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 3
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 4
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 5
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 6
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red ",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 6) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 3
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 4
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 5
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 4) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 3
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 4
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 3) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 3
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 2) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 1) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1 &&
                                                selectedDays[
                                                  i - 2
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else if (i > 0) {
                                              if (
                                                selectedDays[
                                                  i - 1
                                                ].days.indexOf(day) === -1
                                              ) {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      handleDays(
                                                        "day",
                                                        "",
                                                        day,
                                                        i
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn-wrap btn-bg"
                                                    style={
                                                      availableDays
                                                        ? availableDays.indexOf(
                                                            day
                                                          ) > -1
                                                          ? { opacity: "1" }
                                                          : {
                                                              opacity: "0.3",
                                                              background:
                                                                "red !important",
                                                              color: "black",
                                                            }
                                                        : null
                                                    }
                                                  >
                                                    {day}
                                                  </button>
                                                );
                                              }
                                            } else {
                                              return (
                                                <button
                                                  onClick={() =>
                                                    handleDays(
                                                      "day",
                                                      "",
                                                      day,
                                                      i
                                                    )
                                                  }
                                                  type="button"
                                                  className="btn-wrap btn-bg"
                                                  style={
                                                    availableDays
                                                      ? availableDays.indexOf(
                                                          day
                                                        ) > -1
                                                        ? { opacity: "1" }
                                                        : {
                                                            opacity: "0.3",
                                                            background:
                                                              "red !important",
                                                            color: "black",
                                                          }
                                                      : null
                                                  }
                                                >
                                                  {day}
                                                </button>
                                              );
                                            }
                                          })}

                                          <button
                                            type="button"
                                            className="btn-wrap btn"
                                            onClick={weekDisappear}
                                            id="fullWeekBtn"
                                          >
                                            Full Week
                                          </button>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                );
                              })}
                              <div
                                className="AddBtn"
                                style={
                                  selectLocation === false
                                    ? { opacity: "0" }
                                    : { opacity: "1" }
                                }
                              >
                                <div
                                  className=""
                                  style={
                                    selectWeekDay === true
                                      ? { opacity: "0" }
                                      : { opacity: "1" }
                                  }
                                >
                                  <button
                                    className="AddBtn-btn btn btn-primary nav-btns-1"
                                    style={{
                                      background: "#ed1c24",
                                    }}
                                    type="button"
                                    onClick={() => {
                                      setDays([
                                        ...selectedDays,
                                        {
                                          days: [],
                                          timeField: { from: "", to: "" },
                                        },
                                      ]);
                                      setInspection(true);
                                    }}
                                  >
                                    <img
                                      src="/assets/images/cross.png"
                                      width="18px"
                                      alt=""
                                    />
                                    <b>
                                      <>&nbsp;</>
                                      <>&nbsp;</>
                                      <>&nbsp;</> Add another inspection time
                                    </b>
                                    (option)
                                  </button>
                                </div>
                              </div>
                            </div>

                            <input
                              type="button"
                              name="next"
                              className="next action-button pos-5 bg-gray"
                              value="Save & Continue"
                              onClick={handle_next2}
                            />
                          </fieldset>
                        ) : (
                          <fieldset id="fieldset-3">
                            <div className="form-card container">
                              <div className="text-wrap">
                                <p>STEP 3</p>
                                <h6 className="fs-title">
                                  Contact information
                                </h6>
                              </div>

                              <div
                                className="col-9 d-flex"
                                style={{ margin: "0px", padding: "0px" }}
                              >
                                <div className="wrap-1">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Name <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Full Name"
                                    required
                                    className="input-11"
                                    id="name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-2">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Email <span>(Optional)</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Email"
                                    className="input-11"
                                    id="email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Contact Number
                                    <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Contact Number"
                                    required
                                    className="input-11"
                                    id="number"
                                    name="number"
                                    value={contact.number}
                                    onChange={handleContact}
                                  />
                                </div>
                              </div>

                              <div className="col-9 d-flex">
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    City <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Select City"
                                    required
                                    className="input-11"
                                    id="city"
                                    name="city"
                                    value={contact.city}
                                    onChange={handleContact}
                                  />
                                </div>
                                <div className="wrap-3">
                                  <label
                                    className="pay"
                                    style={{ display: "block" }}
                                  >
                                    Address{" "}
                                    <span className="text-warning">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type Your Address"
                                    required
                                    className="input-22"
                                    id="address"
                                    name="address"
                                    value={contact.address}
                                    onChange={handleContact}
                                  />
                                </div>
                              </div>
                              <div
                                className="wrap-3"
                                style={{ marginTop: "50px" }}
                              >
                                <label
                                  className="pay"
                                  style={{ display: "block" }}
                                >
                                  Notes (Optional)
                                </label>
                                <textarea
                                  rows="4"
                                  cols="50"
                                  placeholder="Add Notes"
                                  id="notes"
                                  name="notes"
                                    value={contact.notes}
                                    onChange={handleContact}
                                  required
                                ></textarea>
                              </div>
                            </div>
                            {/* <!-- <input type="submit" id="myElement" className="next action-button review-1 submit"
                                        value="Save & review" /> --> */}

                            <button
                              className="next action-button review-1 bg-gray"
                              type="button"
                              onClick={handleNewDiv}
                            >
                              Save & review
                            </button>
                          </fieldset>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SellSubmit data={sendData} />
        )}
      </div>
    </>
  );
};

export default SellCar;
