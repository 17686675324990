import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../Other/ErrorMessage';
import LoadingComp from '../Other/LoadingComp';

// Define base URL with proper format
const BASE_URL = 'https://api.aboutcars.pk/api';

const Login = () => {

    const navigate = useNavigate();


    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    const [login, setLogin] = React.useState({
        email: "",
        password: "",
    });
    const [error, setError] = React.useState(false);
    const[open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errorData, setErrorData] = React.useState("");


    const handleLoginData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLogin({ ...login, [name]: value });
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            console.log('Sending admin login request with:', login);
            const response = await axios({
                method: 'post',
                url: `${BASE_URL}/admin/login`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: login
            });

            console.log('Admin login response:', response.data);

            if (response.data.status === "success") {
                // Store both token and user data
                localStorage.setItem("adminToken", response.data.data.token);
                
                // Format user data to match the structure expected by the dashboard
                const userData = {
                    Status: "success",
                    code: 200,
                    message: "Admin Logged In Successfully",
                    data: {
                        id: response.data.data.AdminId,
                        fullName: response.data.data.adminName,
                        email: response.data.data.adminEmail,
                        role: 'admin',
                        permissions: ['admin'] // Admin has all permissions
                    }
                };
                
                console.log('Storing admin data in localStorage:', userData);
                localStorage.setItem("user", JSON.stringify(userData));
                
                // Redirect to dashboard
                console.log('Redirecting to dashboard...');
                setTimeout(() => {
                    navigate('/carsDashboard');
                }, 500);
            } else {
                setErrorData(response.data.message || "Login failed. Please try again.");
                setError(true);
                setOpen(true);
            }
        } catch (error) {
            console.error('Login error:', error);
            console.error('Error response:', error.response?.data);
            setErrorData(error.response?.data?.message || "Invalid email or password");
            setError(true);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const userInfo = localStorage.getItem("adminToken");

    useEffect(() => {
        if (userInfo) {
            navigate('/carsDashboard')
        }
    }, [userInfo, navigate])



    return (
        <div>
            {error === true ? <ErrorMessage open={open} setOpen={setOpen} data={errorData} /> : null}
            <div className="row login_wrapper">
                <div className="col-lg-5 col-sm-12">
                    <div className="login_image">
                        <img src="assets/dashboard_images/banner.png" alt='...' />
                    </div>
                </div>
                <div className="col-lg-7 col-sm-12">
                    <div className="login_form">
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="login_form_header">
                                <h1>Login to your account</h1>
                            </div>
                            <div className='login_inputs pt-2'>
                                <form className="login-form" method="POST">
                                    <div className="form-input-material">
                                        <input 
                                            type="email" 
                                            name="email" 
                                            id="username" 
                                            placeholder=" " 
                                            autoComplete=" " 
                                            required 
                                            className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.email}
                                        />
                                        <label htmlFor="username">Email</label>
                                    </div>
                                    <div className="form-input-material position-relative">
                                        <input type={passwordShown ? "text" : "password"} name="password" id="password" placeholder=" " autoComplete="off" required className="form-control-material"
                                            onChange={handleLoginData}
                                            value={login.password}
                                        />
                                        <i className='fa fa-eye position-absolute'
                                            style={{
                                                right: "120px",
                                                top: "25px",
                                                fontSize: "20px",
                                                padding: "5px",
                                                cursor: "pointer",
                                                borderRadius: "50%",
                                                color: "#fff"

                                            }}
                                            onClick={togglePassword}
                                        ></i>
                                        <label htmlFor="password">Password</label>
                                        <button type="button" className='position-absolute'>Forgot?</button>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <button
                                            type="submit" 
                                            className="login_button"
                                            onClick={handleLoginSubmit}
                                            disabled={loading}
                                        >
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login