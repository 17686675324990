import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const userInfo = localStorage.getItem('user');
    
    // Check if we have user info
    if (!userInfo) {
        console.log("No user info found, redirecting to userlogin");
        return <Navigate to="/userlogin" replace />;
    }

    try {
        // Parse user info to verify it's valid
        const userData = JSON.parse(userInfo);
        if (!userData || !userData.data) {
            console.error("Invalid user data format");
            localStorage.removeItem('user');
            return <Navigate to="/userlogin" replace />;
        }
    } catch (error) {
        console.error('User data validation error:', error);
        // If there's an error with the user data, we'll redirect to login
        localStorage.removeItem('user');
        return <Navigate to="/userlogin" replace />;
    }
    
    return children;
};

export default ProtectedRoute; 