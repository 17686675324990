import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../config/config';
import "./admin_style.css";

const EditAccount = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        contactNo: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const userInfo = localStorage.getItem('user');
            if (!userInfo) {
                navigate('/adminlogin');
                return;
            }

            try {
                const user = JSON.parse(userInfo);
                setUserData(prevState => ({
                    ...prevState,
                    name: user.data.adminName || '',
                    email: user.data.adminEmail || '',
                    contactNo: user.data.adminContact || ''
                }));
            } catch (error) {
                console.error('Error parsing user data:', error);
                navigate('/adminlogin');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear messages when user starts typing
        setError(null);
        setSuccess(null);
    };

    const showMessage = (message, isError = false) => {
        if (isError) {
            setError(message);
            setSuccess(null);
        } else {
            setSuccess(message);
            setError(null);
        }
        
        // Clear message after 3 seconds
        setTimeout(() => {
            setError(null);
            setSuccess(null);
        }, 3000);
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setError(null);
        setSuccess(null);

        try {
            const userInfo = localStorage.getItem('user');
            const user = JSON.parse(userInfo);
            const userId = user.data.AdminId;

            const response = await axios.post(
                `${API_ENDPOINT}/admin/edit-profile`,
                {
                    id: userId,
                    name: userData.name,
                    contactNo: userData.contactNo
                }
            );

            if (response.data.status === "success") {
                const updatedUser = {
                    ...user,
                    data: {
                        ...user.data,
                        adminName: userData.name,
                        adminContact: userData.contactNo
                    }
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                showMessage('Profile updated successfully');
            } else {
                showMessage(response.data.message || 'Failed to update profile', true);
            }
        } catch (err) {
            showMessage(err.response?.data?.message || 'Failed to update profile', true);
        } finally {
            setSubmitting(false);
        }
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setError(null);
        setSuccess(null);

        if (userData.newPassword !== userData.confirmPassword) {
            showMessage('New passwords do not match', true);
            setSubmitting(false);
            return;
        }

        try {
            const userInfo = localStorage.getItem('user');
            const user = JSON.parse(userInfo);
            const userId = user.data.AdminId;

            const response = await axios.post(
                `${API_ENDPOINT}/admin/update-password`,
                {
                    id: userId,
                    password: userData.currentPassword,
                    new_password: userData.newPassword,
                    confirm_password: userData.confirmPassword
                }
            );

            if (response.data.Status === "success") {
                showMessage('Password updated successfully');
                setUserData(prevState => ({
                    ...prevState,
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                }));
            } else {
                showMessage(response.data.message || 'Failed to update password', true);
            }
        } catch (err) {
            showMessage(err.response?.data?.message || 'Failed to update password', true);
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <img src="/static/media/infinityani.7546ce40c090b21dc36d.svg" alt="Loading..." />
            </div>
        );
    }

    return (
        <div className="edit-account-container">
            <div className="edit-account-card">
                <div className="edit-account-header">
                    <h2>Edit Account</h2>
                    <div className="tab-buttons">
                        <button 
                            className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
                            onClick={() => setActiveTab('profile')}
                        >
                            Profile
                        </button>
                        <button 
                            className={`tab-button ${activeTab === 'password' ? 'active' : ''}`}
                            onClick={() => setActiveTab('password')}
                        >
                            Password
                        </button>
                    </div>
                </div>

                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="alert alert-success" role="alert">
                        {success}
                    </div>
                )}

                {activeTab === 'profile' ? (
                    <form onSubmit={handleUpdateProfile} className="edit-form">
                        <div className="form-group">
                            <label>Full Name</label>
                            <input
                                type="text"
                                name="name"
                                value={userData.name}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                value={userData.email}
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label>Contact Number</label>
                            <input
                                type="text"
                                name="contactNo"
                                value={userData.contactNo}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-actions">
                            <button type="submit" className="btn-save" disabled={submitting}>
                                {submitting ? (
                                    <img 
                                        src="/static/media/infinityani.7546ce40c090b21dc36d.svg" 
                                        alt="Saving..." 
                                        className="button-loader"
                                    />
                                ) : 'Save Changes'}
                            </button>
                        </div>
                    </form>
                ) : (
                    <form onSubmit={handleUpdatePassword} className="edit-form">
                        <div className="form-group">
                            <label>Current Password</label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={userData.currentPassword}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>New Password</label>
                            <input
                                type="password"
                                name="newPassword"
                                value={userData.newPassword}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                                minLength="6"
                            />
                        </div>
                        <div className="form-group">
                            <label>Confirm New Password</label>
                            <input
                                type="password"
                                name="confirmPassword"
                                value={userData.confirmPassword}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                                minLength="6"
                            />
                        </div>
                        <div className="form-actions">
                            <button type="submit" className="btn-save" disabled={submitting}>
                                {submitting ? (
                                    <img 
                                        src="/static/media/infinityani.7546ce40c090b21dc36d.svg" 
                                        alt="Updating..." 
                                        className="button-loader"
                                    />
                                ) : 'Update Password'}
                            </button>
                        </div>
                    </form>
                )}
            </div>

            <style jsx>{`
                .edit-account-container {
                    padding: 2rem;
                    max-width: 600px;
                    margin: 0 auto;
                }

                .edit-account-card {
                    background: white;
                    border-radius: 12px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    padding: 2rem;
                }

                .edit-account-header {
                    margin-bottom: 2rem;
                    text-align: center;
                }

                .edit-account-header h2 {
                    color: #333;
                    margin-bottom: 1.5rem;
                }

                .tab-buttons {
                    display: flex;
                    gap: 1rem;
                    justify-content: center;
                    margin-bottom: 2rem;
                }

                .tab-button {
                    padding: 0.75rem 1.5rem;
                    border: none;
                    background: none;
                    color: #666;
                    font-weight: 500;
                    cursor: pointer;
                    position: relative;
                    transition: all 0.3s ease;
                }

                .tab-button:after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #d9534f;
                    transform: scaleX(0);
                    transition: transform 0.3s ease;
                }

                .tab-button.active {
                    color: #d9534f;
                }

                .tab-button.active:after {
                    transform: scaleX(1);
                }

                .edit-form {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                }

                .form-group {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                .form-group label {
                    color: #666;
                    font-weight: 500;
                }

                .form-control {
                    padding: 0.75rem;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    font-size: 1rem;
                    transition: all 0.3s ease;
                }

                .form-control:focus {
                    outline: none;
                    border-color: #d9534f;
                    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.2);
                }

                .form-control:disabled {
                    background: #f8f9fa;
                    cursor: not-allowed;
                }

                .form-actions {
                    margin-top: 1rem;
                }

                .btn-save {
                    width: 100%;
                    padding: 0.75rem;
                    background: #d9534f;
                    color: white;
                    border: none;
                    border-radius: 8px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: all 0.3s ease;
                }

                .btn-save:hover {
                    background: #c9302c;
                    transform: translateY(-1px);
                }

                .btn-save:disabled {
                    background: #6c757d;
                    cursor: not-allowed;
                    transform: none;
                }

                .alert {
                    padding: 1rem;
                    border-radius: 8px;
                    margin-bottom: 1.5rem;
                }

                .alert-danger {
                    background: #f8d7da;
                    color: #721c24;
                    border: 1px solid #f5c6cb;
                }

                .alert-success {
                    background: #d4edda;
                    color: #155724;
                    border: 1px solid #c3e6cb;
                }

                @media (max-width: 768px) {
                    .edit-account-container {
                        padding: 1rem;
                    }

                    .edit-account-card {
                        padding: 1.5rem;
                    }
                }

                .loading-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 400px;
                }

                .loading-container img {
                    width: 80px;
                    height: 80px;
                }

                .button-loader {
                    width: 24px;
                    height: 24px;
                    margin: -4px 0;
                }

                .btn-save {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 48px;
                }
            `}</style>
        </div>
    );
};

export default EditAccount;