import React, { forwardRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const SlideTransition = forwardRef((props, ref) => (
    <Slide ref={ref} {...props} direction="down" />
));

const ErrorMessage = ({ open, setOpen, data }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar 
            open={open} 
            autoHideDuration={4000} 
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            TransitionComponent={SlideTransition}
        >
            <Alert 
                onClose={handleClose} 
                severity="error"
            >
                {data}
            </Alert>
        </Snackbar>
    );
};

export default ErrorMessage;