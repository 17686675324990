import React, { Fragment, useEffect, useState, useRef } from 'react';
import "./style_home.css";
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_ENDPOINT } from '../config/config';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCar, 
    faCalendarAlt, 
    faTachometerAlt, 
    faCog, 
    faGasPump,
    faSearch,
    faPalette
} from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const [years, setYears] = useState(0);
    const [happyCustomers, setHappyCustomers] = useState(0);
    const [vehicles, setVehicles] = useState(0);
    const [staff, setStaff] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [featuredVehicles, setFeaturedVehicles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentImageIndices, setCurrentImageIndices] = useState({});
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const vehiclesRef = useRef(null);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const achievementRef = useRef(null);
    const serviceRef = useRef(null);
    const consultancyRef = useRef(null);
    const customersRef = useRef(null);
    const contactRef = useRef(null);

    const [animated, setAnimated] = useState({
        achievements: [false, false, false, false],
        service: false,
        consultancy: false,
        customers: false,
        contact: false,
        vehicles: false,
    });

    // Add observer for scroll animations
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3,
        };

        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const targetElement = entry.target;
                    targetElement.classList.add('visible');
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        const animatedElements = document.querySelectorAll('.animate-on-scroll');
        animatedElements.forEach(el => observer.observe(el));

        return () => {
            animatedElements.forEach(el => observer.unobserve(el));
        };
    }, []);

    useEffect(() => {

        const fetchFeaturedVehicles = async () => {
            try {
                const response = await fetch(`${API_ENDPOINT}/cars/all-sell-cars`);
                const data = await response.json();
                if (data.Status === "Success") {
                    // Filter for active cars in stock with images, then sort by date and take latest 3
                    const sortedVehicles = data.data
                        .filter(vehicle => 
                            vehicle.carImages.length > 0 && // Has images
                            vehicle.active === true && // Is active
                            vehicle.in_stock === true // Is in stock
                        )
                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date (newest first)
                        .slice(0, 3); // Take only the latest 3 vehicles

                    setFeaturedVehicles(sortedVehicles);
                }
            } catch (error) {
                console.error('Error fetching featured vehicles:', error);
            }
        };


        fetchFeaturedVehicles();

        const handleScroll = () => {
            // Check if achievement section is in the viewport
            if (achievementRef.current) {
                const rect = achievementRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom >= 0 && !hasAnimated) {
                    setHasAnimated(true);
                    startCounters(); // Start counters for achievements
                }
                // Check if each individual achievement box is in the viewport
                const boxes = achievementRef.current.querySelectorAll('.achievement-box');
                boxes.forEach((box, index) => {
                    const boxRect = box.getBoundingClientRect();
                    if (boxRect.top < window.innerHeight && boxRect.bottom >= 0 && !animated.achievements[index]) {
                        setAnimated(prev => {
                            const newAnimated = { ...prev };
                            newAnimated.achievements[index] = true;
                            return newAnimated;
                        });
                    }
                });
            }

            // Check if other sections are in the viewport
            const sections = [
                { ref: serviceRef, key: 'service' },
                { ref: consultancyRef, key: 'consultancy' },
                { ref: customersRef, key: 'customers' },
                { ref: contactRef, key: 'contact' },
                { ref: vehiclesRef, key: 'vehicles' },
            ];
            sections.forEach(({ ref, key }) => {
                if (ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    if (rect.top < window.innerHeight && rect.bottom >= 0 && !animated[key]) {
                        setAnimated(prev => ({
                            ...prev,
                            [key]: true,
                        }));
                    }
                }
            });
        };

        const startCounters = () => {
            const yearsTarget = new Date().getFullYear() - 2015;
            let yearsCount = 0;
            const yearsInterval = setInterval(() => {
                if (yearsCount < yearsTarget) {
                    yearsCount++;
                    setYears(yearsCount);
                } else {
                    clearInterval(yearsInterval);
                }
            }, 30);

            const happyCustomersTarget = 5000;
            let happyCustomersCount = 4800;
            const happyCustomersInterval = setInterval(() => {
                if (happyCustomersCount < happyCustomersTarget) {
                    happyCustomersCount++;
                    setHappyCustomers(happyCustomersCount);
                } else {
                    clearInterval(happyCustomersInterval);
                }
            }, 30);

            const vehiclesTarget = Math.floor(Math.random() * (50 - 25 + 1)) + 25;
            let vehiclesCount = 0;
            const vehiclesInterval = setInterval(() => {
                if (vehiclesCount < vehiclesTarget) {
                    vehiclesCount++;
                    setVehicles(vehiclesCount);
                } else {
                    clearInterval(vehiclesInterval);
                }
            }, 30);

            const staffTarget = 12;
            let staffCount = 0;
            const staffInterval = setInterval(() => {
                if (staffCount < staffTarget) {
                    staffCount++;
                    setStaff(staffCount);
                } else {
                    clearInterval(staffInterval);
                }
            }, 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasAnimated, animated]);

    useEffect(() => {
        // Initialize image indices for all vehicles
        const indices = {};
        featuredVehicles.forEach(vehicle => {
            indices[vehicle._id] = 0;
        });
        setCurrentImageIndices(indices);
    }, [featuredVehicles]);

    const getCars = async () => {
        try {
            const response = await axios.get(`${API_ENDPOINT}/stock/featured-cars`);
            if (response.data && response.data.success) {
                setCars(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching featured cars:', error);
            setError(error.message || 'Error fetching featured cars');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCars();
    }, []);

    // Add capitalizeFirst function
    const capitalizeFirst = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    return (
        <>
            <div className="container-fluid m-0 p-0" style={{ top: 0, left: 0, right: 0, zIndex: 1 }}>
                <div style={{ backgroundColor: "#20111E", color: "white" }} className="py-3">
                    <div className="wrapper-call" id="header-wrapper">
                    <div className="row align-items-center m-0">
                        <div className="col text-left mb-2">
                            <div className="running-text">
                                <p className="mb-0 animate__animated animate__slideInRight">Buy, import, and sell your car through us. It's all about cars!</p>
                            </div>
                        </div>
                        <div className="col text-right d-flex justify-content-end">
                            <div className="mx-2">
                                    <i className="ri-time-line"></i>
                                <span className="ml-1">Mon – Sun (10 AM – 7 PM)</span>
                            </div>
                            <div className="mx-2">
                                    <i className="ri-mail-send-line"></i>
                                    <a href="mailto:info@aboutcars.pk" className="ml-1 text-white text-decoration-none">info@aboutcars.pk</a>
                            </div>
                            <div className="mx-2">
                                    <i className="ri-phone-fill"></i>
                                    <a href="tel:03226882277" className="ml-1 text-white text-decoration-none">0322 6882277</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="navbar navbar-expand-lg navbar-light m-0">
                        <div className="wrapper-call">
                            <div className="container-fluid p-0">
                            <a className="navbar-brand" href="/">
                                <img className="logo" src="assets/images/logo.png" style={{ width: "50%" }} alt="" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <ul className="navbar-nav mr-auto mb-2 mb-md-0">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#service">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#Consultancy">Consultancy</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contact">Contact</a>
                                    </li>
                                </ul>
                                <div className="d-flex nav-btns">
                                    <Link className="nav-btns-1 mr-3" to="/sell">Sell Now</Link>
                                    <Link className="ms-2 nav-btns-2" to="/buy">Buy Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <section className="container-fluid head-sec p-0">
                <div className="row m-0" id="backg">
                    <div className="wrapper-call d-flex align-items-center">
                        <div className="row m-0 w-100">
                    <div className="col-md-6 col-lg-7 col-sm-12 hero-text">
                        <h1 className="header-text mb-3 ps-2 animate__animated animate__fadeInUp animate__delay-1s">The best car <br /> dealership operating<br />in the twin cities</h1>
                        <p className="para mb-4 ps-2 animate__animated animate__slideInLeft animate__delay-2s">We offer customer satisfaction whether you <br /> are here to buy your new dream car or sell an old one
                        </p>
                        <a className="ms-2 nav-btns-2" href="#service">Explore Now</a>
                    </div>
                    <div className="col-lg-5 col-sm-12 image p-0">
                                <div className='car_hero_image_div'>
                            <img
                                src="assets/images/457.png"
                                        className="img-res img-res-car animate__animated animate__fadeInRight animate__delay-1s"
                                alt="Car"
                            />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid animate-on-scroll" id="service" ref={serviceRef}>
                <div className="container wrapper-call">
                    <h2 className={`text-center mb-5 border-t ${animated.service ? 'animate__animated animate__fadeInDown' : ''}`}>Our Services</h2>
                <div className="row justify-content-around">
                    <div className="col-md-4 col-sm-6 mb-4">
                            <div className={`service-box animate-on-scroll`}>
                                <i className="bx bxs-car-garage text-primary mb-4" style={{ fontSize: '3.5rem' }}></i>
                            <h3>Sell a Car</h3>
                            <p>We help you to sell your car by showcasing it at one of our renowned showrooms or by simply providing us with pictures and details of your car to find the best possible buyer for it.</p>
                            <Link className="btn nav-btns-2" style={{ marginTop: '23px' }} to="/sell">Sell Now</Link>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                            <div className={`service-box animate-on-scroll`}>
                                <i className="bx bxs-key text-primary mb-4" style={{ fontSize: '3.5rem' }}></i>
                            <h3>Buy a Car</h3>
                            <p>We help you to buy your car by showcasing it at one of our renowned showrooms or by simply providing us with pictures and details of your car to find the best possible seller for it.</p>
                            <Link className="btn nav-btns-2" style={{ marginTop: '23px' }} to="/buy">Buy Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid my-5" ref={vehiclesRef}>
                <div className="container wrapper-call">
    <h2 className="text-center mb-4 border-text">Featured Vehicles</h2>
    <style>
        {`
            .carousel-inner {
                height: auto !important;
                margin-top: 0px !important;
                border: 1px solid #ebcdcd;
            }
            .full-stock-btn {
                background-color: #d9534f; /* Red background */
                color: white;
                padding: 12px 25px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                transition: background-color 0.3s ease;
            }
            .full-stock-btn:hover {
                background-color: #c9302c; /* Darker red on hover */
            }
            .full-stock-btn i {
                margin-left: 8px;
                font-size: 18px;
            }

            /* Car Card Styles */
            .car-card {
                background: white;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                transition: transform 0.3s ease;
                height: 100%;
                margin: 0;
            }

            .car-card:hover {
                transform: translateY(-5px);
            }

            .car-image {
                position: relative;
                height: 200px;
                overflow: hidden;
            }

            .car-main-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;
            }

            .car-card:hover .car-main-image {
                transform: scale(1.05);
            }

            .new-badge {
                position: absolute;
                top: 12px;
                right: 12px;
                background: #dc3545;
                color: white;
                padding: 6px 12px;
                border-radius: 20px;
                font-weight: 600;
                font-size: 0.8rem;
            }

            .car-content {
                padding: 1.25rem;
            }

            .car-title {
                font-size: 1.2rem;
                font-weight: 600;
                color: #333;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }

            .car-icon {
                color: #ed1c24;
            }

            .car-specs {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-bottom: 1rem;
            }

            .spec-item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                color: #666;
                font-size: 0.9rem;
            }

            .spec-item svg {
                width: 16px;
            }

            .car-price {
                margin: 1rem 0;
                text-align: left;
            }

            .price-amount {
                font-size: 1.25rem;
                font-weight: 600;
                color: #333;
            }

            .view-details-btn {
                display: inline-block;
                width: 100%;
                padding: 0.75rem;
                background: #ed1c24;
                color: white;
                text-align: center;
                border-radius: 25px;
                text-decoration: none;
                font-weight: 600;
                transition: all 0.3s ease;
            }

            .view-details-btn:hover {
                background: #d91414;
                color: white;
                transform: translateY(-2px);
            }

            .row.g-3 {
                margin-left: 0;
                margin-right: 0;
            }

            .col-md-4 {
                padding: 0.75rem;
            }

            @media (max-width: 768px) {
                .container-fluid {
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                }
                .col-md-4 {
                    padding: 0.5rem;
                }
                .car-image {
                    height: 180px;
                }
                
                .car-content {
                    padding: 1rem;
                }
                
                .car-title {
                    font-size: 1.1rem;
                }
            }

            /* Section Styles */
            .border-text {
                position: relative;
                padding-bottom: 15px;
                margin-bottom: 30px;
            }

            .border-text:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 80px;
                height: 3px;
                background-color: #dc3545;
            }

            /* Loading and Error States */
            .no-results {
                text-align: center;
                padding: 3rem;
                background: white;
                border-radius: 12px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
            }

            .no-results svg {
                margin-bottom: 1rem;
            }

            .no-results p {
                margin: 0;
                color: #666;
            }
        `}
    </style>
    <div className="row justify-content-center">
        {loading ? (
            <div className="col-12 text-center p-5">
                <Spinner animation="border" variant="danger" />
            </div>
        ) : error ? (
            <div className="col-12">
                <div className="alert alert-danger">{error}</div>
                                                    </div>
        ) : cars.length === 0 ? (
            <div className="col-12 text-center">
                <div className="no-results p-5">
                    <FontAwesomeIcon icon={faSearch} className="mb-3" style={{ fontSize: '3rem', color: '#dc3545' }} />
                    <p className="h4 text-muted">No cars available in stock.</p>
                                            </div>
                                        </div>
        ) : (
            cars.map((car) => (
                <div className="col-md-4" key={car._id}>
                    <div className="car-card">
                        <div className="car-image">
                            <img 
                                src={car.images && car.images.length > 0 
                                    ? `http://api.aboutcars.pk${car.images[0]}`
                                    : '/placeholder-car.jpg'} 
                                alt={`${car.make} ${car.model}`}
                                className="car-main-image"
                            />
                        </div>
                        <div className="car-content">
                            <h3 className="car-title">
                                <FontAwesomeIcon icon={faCar} className="car-icon" />
                                {car.year} {car.make} {car.model}
                            </h3>
                            <div className="car-specs">
                                <div className="spec-item">
                                    <FontAwesomeIcon icon={faPalette} style={{ color: '#ed1c24' }} />
                                    <span>Color: {capitalizeFirst(car.color)}</span>
                                </div>
                                <div className="spec-item">
                                    <FontAwesomeIcon icon={faTachometerAlt} style={{ color: '#ed1c24' }} />
                                    <span>Mileage: {car.mileage ? car.mileage.toLocaleString() : '0'} km</span>
                                </div>
                                <div className="spec-item">
                                    <FontAwesomeIcon icon={faCog} style={{ color: '#ed1c24' }} />
                                    <span>Transmission: {capitalizeFirst(car.transmission)}</span>
                                </div>
                            </div>
                            <div className="car-price">
                                <span className="price-amount">PKR {car.price ? car.price.toLocaleString() : '0'}</span>
                            </div>
                            <Link to={`/car/${car._id}`} className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            ))
        )}
    </div>

    {/* Full Stock Button */}
    <div className="text-center mt-4">
        <a href="/stock" className="ms-2 nav-btns-2">
                            View Full Stock
                    </a>
                    </div>
    </div>
</section>

            <section id="Consultancy" ref={consultancyRef} style={{ backgroundColor: "#fff", padding: "50px 0" }}>
                <div className="container wrapper-call">
                    <div className="row align-items-center">
                    <div className={`col-md-6 animate__animated ${animated.consultancy ? 'animate__fadeInLeft' : ''}`}>
                    <img src="https://aboutcars.pk/assets/images/445.png" className="img-fluid" alt="Consultancy" />
                        </div>
                        <div className="col-md-6 text-center">
                            <h2 className={`mb-4 animate__animated ${animated.consultancy ? 'animate__fadeInRight' : ''}`}>Consultancy Services</h2>
                            <p className={`mb-5 animate__animated ${animated.consultancy ? 'animate__fadeInRight' : ''}`}>
                                We provide expert consultancy services to help you make the best choices in buying or selling your vehicle.
                            </p>
                            <a href="tel:03226882277" className="btn nav-btns-1" style={{ cursor: 'pointer' }}>Call Now</a>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="container-fluid mt-5 animate-on-scroll" ref={achievementRef} style={{ marginTop: "150px" }}>
                <div className="container wrapper-call">
                <h2 className="text-center animate__animated animate__fadeInDown">What We Have Achieved</h2>
                <div className="row text-center mt-4">
                        <div className="col-md-3 mb-4 achievement-box animate-on-scroll">
                            <div className="card">
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{years}+</h3>
                                <p style={{ color: "red" }}>Years of Excellence</p>
                            </div>
                        </div>
                    </div>
                        <div className="col-md-3 mb-4 achievement-box animate-on-scroll">
                            <div className="card">
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{happyCustomers}+</h3>
                                <p style={{ color: "red" }}>Happy Customers</p>
                            </div>
                        </div>
                    </div>
                        <div className="col-md-3 mb-4 achievement-box animate-on-scroll">
                            <div className="card">
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{vehicles}</h3>
                                <p style={{ color: "red" }}>Vehicles In Stock</p>
                            </div>
                        </div>
                    </div>
                        <div className="col-md-3 mb-4 achievement-box animate-on-scroll">
                            <div className="card">
                            <div className="card-body">
                                <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{staff}</h3>
                                <p style={{ color: "red" }}>Qualified Staff</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" ref={contactRef} style={{ backgroundColor: "rgb(255 255 255)", padding: "50px 0" }}>
                <div className="container wrapper-call text-center">
                    <h2 className={`mb-4 animate__animated ${animated.contact ? 'animate__fadeInDown' : ''}`}>Contact Us</h2>
                    <p className={`mb-5 animate__animated ${animated.contact ? 'animate__fadeInDown' : ''}`}>Get in touch with us for any inquiries or assistance.</p>
                    <a className="btn nav-btns-1" href="tel:03226882277" style={{ borderRadius: "25px", padding: "10px 20px", display: "inline-block" }}>Call Now</a>
                </div>
            </section>

            <section ref={customersRef} className="container-fluid mt-5 animate-on-scroll" style={{ marginTop: "80px", paddingBottom: "50px" }}>
                <div className="container wrapper-call">
                <div className="row align-items-center">
                    <div className="col-md-6 animate__animated animate__fadeInLeft" style={{ opacity: animated.customers ? 1 : 0 }}>
                        <h2 className="happy-customers-title">Our Happy Customers</h2>
                        <p>We strive to provide the best service and support to our customers. Their satisfaction is our top priority, and we take pride in their success stories.</p>
                    </div>
                    <div className="col-md-6 animate__animated animate__fadeInRight" style={{ opacity: animated.customers ? 1 : 0 }}>
                        <img
                            src="assets/images/happycs.jpg"
                            alt="Group of happy customers celebrating their experience with our service."
                                className="happy-customers-image"
                        />
                        </div>
                    </div>
                </div>
            </section>

            {/* New Contact Section */}
            <section ref={contactRef} style={{ background: "linear-gradient(to right, #f9fafc, #e0e7ff)" }} id="contact">
                <div className="container wrapper-call">
                    <div className="row d-flex align-items-center pb-3 dis-mobile" style={{ height: "100%" }}>
                        <h3 className="col-9 pt-2 animate__animated animate__fadeInUp" style={{ color: "#333", textAlign: "left", opacity: animated.contact ? 1 : 0 }}>
                            <span className="pxs" style={{ fontWeight: "bold", fontSize: "24px" }}>Want to import a car?</span>
                            <span style={{ fontWeight: 400 }}></span>
                            <span style={{ fontWeight: 400, fontSize: "16px", padding: "10px 0", display: "block" }} className="pxs-2">
                                Our Agents Help You on Every Step
                            </span>
                        </h3>
                        <div className="col-3 mob-btn text-end">
                            <a
                                className="btn btn-primary ms-2 nav-btns-2 nav-btns-3 animate__animated animate__fadeInUp"
                                href="tel:03226882277"
                                style={{ borderRadius: "25px", color: "blank", opacity: animated.contact ? 1 : 0 }}>
                                <i className="ri-phone-fill" id="phone-icon" style={{ color: "blank" }}></i> Call Now
                            </a>

                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid footer">
                <div className="wrapper-call">
                <footer className="row" id="footer">
                    <div className="col-md-3 col-sm-12 mb-3">
                        <a href="/" className="d-flex align-items-center justify-content-center mb-3 link-dark text-decoration-none">
                            <img src="assets/images/logo-white.png" className="me-2" width="80%" alt='Gray' />
                        </a>
                    </div>

                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Address</a></li>
                            <li className="nav-item mb-2"><a href="https://www.google.com/search?client=opera&q=G-81%2C+Food+Street%2C+Cricket+Stadium+Road%2C+Rawalpindi&sourceid=opera&ie=UTF-8&oe=UTF-8" className="nav-link p-0 text-muted">G-81, Food Street, Cricket Stadium Road,
                                <br />Rawalpindi</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 col-sm-12">
                        <ul className="nav flex-column align-items-center">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Contact</a></li>
                            <li className="nav-item mb-2">
                                <a href="tel:03226682277" className="nav-link p-0 text-muted d-flex align-items-center">
                                    <i className="ri-phone-fill me-2"></i>
                                    <span style={{ color: "white" }}>0322-6682277</span>
                                </a>
                            </li>
                            <li className="nav-item mb-2">
                                <a href="mailto:info@aboutcars.pk" className="nav-link p-0 text-muted d-flex align-items-center">
                                    <i className="ri-mail-send-line me-2"></i>
                                    <span style={{ color: "white" }}>info@aboutcars.pk</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>

                <div className="row" style={{ borderTop: "1px solid #f3f4f5" }}>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <button className="btn btn-link text-bottom" onClick={handleShow}>
                            Privacy Policy - Terms of use
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <p className="text-bottom-1">About Cars © {new Date().getFullYear()}</p>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center">
                        <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
                            <div className="d-flex flex-column flex-sm-row justify-content-between"></div>
                            <div>
                                <ul className="list-unstyled d-flex share-icon">
                                        <li className="ms-3"><a className="link-dark" href="https://www.facebook.com/aboutcars.pk/"><i className="bx bxl-facebook"></i></a></li>
                                        <li className="ms-3"><a className="link-dark" href="https://www.google.com/search?q=aboutcars+pk"><i className="bx bxl-google"></i></a></li>
                                        <li className="ms-3"><a className="link-dark" href="https://mobile.twitter.com/AboutCarsPk"><i className="bx bxl-twitter"></i></a></li>
                                        <li className="ms-3"><a className="link-dark" href="https://www.instagram.com/aboutcars.pk/"><i className="bx bxl-instagram"></i></a></li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Privacy Policy Modal */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="privacyPolicyModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="privacyPolicyModalLabel">Privacy Policy</h5>
                            <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Your privacy is important to us. It is About Cars' policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://aboutcars.pk" target="_blank" rel="noopener noreferrer">aboutcars.pk</a>, and other sites we own and operate.</p>

                            <h5 className="font-weight-bold">Information We Collect</h5>
                            <p>We may ask for personal information which may include your name, email address, and phone number.</p>

                            <h5 className="font-weight-bold">How We Use Your Information</h5>
                            <p>We may use the information we collect from you in the following ways:</p>
                            <ul className="list-unstyled">
                                <li>• To personalize your experience</li>
                                <li>• To improve our website</li>
                                <li>• To send periodic emails regarding your order or other products and services</li>
                            </ul>

                            <h5 className="font-weight-bold">Data Security</h5>
                            <p>We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site.</p>

                            <h5 className="font-weight-bold">Changes to This Privacy Policy</h5>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                            <h5 className="font-weight-bold">Contact Us</h5>
                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            <p>Email: <a href="mailto:support@aboutcars.pk">support@aboutcars.pk</a></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;