import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCar, 
    faCalendarAlt, 
    faPalette, 
    faTachometerAlt, 
    faCog, 
    faMoneyBillWave,
    faSort,
    faFilter,
    faSearch,
    faTimesCircle,
    faGasPump,
    faArrowLeft,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import { API_ENDPOINT } from '../../config/config';

const Stock = () => {
    const [cars, setCars] = useState([]);
    const [filteredCars, setFilteredCars] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Filter states
    const [filters, setFilters] = useState({
        priceRange: { min: '', max: '' },
        make: '',
        model: '',
        year: '',
        transmission: '',
        color: '',
        mileageRange: { min: '', max: '' }
    });
    const [sortBy, setSortBy] = useState('newest');
    const [availableMakes, setAvailableMakes] = useState([]);
    const [availableModels, setAvailableModels] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [availableColors, setAvailableColors] = useState([]);

    // Add capitalizeFirst function
    const capitalizeFirst = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const getCars = async () => {
        try {
            const response = await axios.get(`${API_ENDPOINT}/stock/cars`);
            if (response.data && response.data.success) {
                const activeCars = response.data.data.filter(car => car.status === 'active');
                setCars(activeCars);
                setFilteredCars(activeCars);
                
                // Extract unique values for filters
                const makes = [...new Set(activeCars.map(car => car.make))];
                const models = [...new Set(activeCars.map(car => car.model))];
                const years = [...new Set(activeCars.map(car => car.year))];
                const colors = [...new Set(activeCars.map(car => car.color))];
                
                setAvailableMakes(makes);
                setAvailableModels(models);
                setAvailableYears(years);
                setAvailableColors(colors);
            }
        } catch (error) {
            console.error('Error fetching car data:', error);
            setError(error.message || 'Error fetching car data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCars();
    }, []);

    // Apply filters
    const applyFilters = () => {
        let filtered = [...cars];

        if (filters.priceRange.min) {
            filtered = filtered.filter(car => car.price >= Number(filters.priceRange.min));
        }
        if (filters.priceRange.max) {
            filtered = filtered.filter(car => car.price <= Number(filters.priceRange.max));
        }
        if (filters.make) {
            filtered = filtered.filter(car => car.make.toLowerCase() === filters.make.toLowerCase());
        }
        if (filters.model) {
            filtered = filtered.filter(car => car.model.toLowerCase() === filters.model.toLowerCase());
        }
        if (filters.year) {
            filtered = filtered.filter(car => car.year === filters.year);
        }
        if (filters.transmission) {
            filtered = filtered.filter(car => car.transmission.toLowerCase() === filters.transmission.toLowerCase());
        }
        if (filters.color) {
            filtered = filtered.filter(car => car.color.toLowerCase() === filters.color.toLowerCase());
        }
        if (filters.mileageRange.min) {
            filtered = filtered.filter(car => car.mileage >= Number(filters.mileageRange.min));
        }
        if (filters.mileageRange.max) {
            filtered = filtered.filter(car => car.mileage <= Number(filters.mileageRange.max));
        }

        // Apply sorting
        switch (sortBy) {
            case 'priceAsc':
                filtered.sort((a, b) => a.price - b.price);
                break;
            case 'priceDesc':
                filtered.sort((a, b) => b.price - a.price);
                break;
            case 'newest':
                filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case 'mileageAsc':
                filtered.sort((a, b) => a.mileage - b.mileage);
                break;
            default:
                break;
        }

        setFilteredCars(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filters, sortBy]);

    const handleFilterChange = (field, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const resetFilters = () => {
        setFilters({
            priceRange: { min: '', max: '' },
            make: '',
            model: '',
            year: '',
            transmission: '',
            color: '',
            mileageRange: { min: '', max: '' }
        });
        setSortBy('newest');
    };

    return (
        <div className="stock-page">
            <div className="page-header">
                <div className="container-fluid px-3">
                    <div className="back-section mb-2">
                        <Link to="/" className="back-link">
                            <FontAwesomeIcon icon={faArrowLeft} /> Back to Home
                        </Link>
                    </div>
                    <h2 className="text-center mb-0">
                        <FontAwesomeIcon icon={faCar} className="me-2" />
                        Vehicles in Stock
                    </h2>
                </div>
            </div>
            <div className="container-fluid px-3">
                <Card className="mb-3 filter-card">
                    <Card.Body className="p-3">
                        <div className="filter-header">
                            <div className="filter-title">
                                <FontAwesomeIcon icon={faFilter} className="filter-icon" />
                                <h3>Filter Vehicles</h3>
                            </div>
                            <Button variant="outline-danger" onClick={resetFilters} className="reset-btn">
                                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                Reset Filters
                            </Button>
                        </div>
                        <div className="filter-sections">
                            <div className="filter-section">
                                <Row className="g-2">
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faCar} className="me-2" />
                                                Make
                                            </Form.Label>
                                            <Form.Select 
                                                value={filters.make}
                                                onChange={(e) => handleFilterChange('make', e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">All Makes</option>
                                                {availableMakes.map(make => (
                                                    <option key={make} value={make}>{capitalizeFirst(make)}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faCar} className="me-2" />
                                                Model
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.model}
                                                onChange={(e) => handleFilterChange('model', e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">All Models</option>
                                                {availableModels.map(model => (
                                                    <option key={model} value={model}>{capitalizeFirst(model)}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                                                Year
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.year}
                                                onChange={(e) => handleFilterChange('year', e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">All Years</option>
                                                {availableYears.map(year => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faPalette} className="me-2" />
                                                Color
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.color}
                                                onChange={(e) => handleFilterChange('color', e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">All Colors</option>
                                                {availableColors.map(color => (
                                                    <option key={color} value={color}>{capitalizeFirst(color)}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="filter-section">
                                <h4 className="section-title">Price & Features</h4>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faMoneyBillWave} className="me-2" />
                                                Price Range (PKR)
                                            </Form.Label>
                                            <div className="range-inputs">
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Min"
                                                    value={filters.priceRange.min}
                                                    onChange={(e) => handleFilterChange('priceRange', { ...filters.priceRange, min: e.target.value })}
                                                    className="custom-input"
                                                />
                                                <span className="range-separator">to</span>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Max"
                                                    value={filters.priceRange.max}
                                                    onChange={(e) => handleFilterChange('priceRange', { ...filters.priceRange, max: e.target.value })}
                                                    className="custom-input"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faTachometerAlt} className="me-2" />
                                                Mileage Range (km)
                                            </Form.Label>
                                            <div className="range-inputs">
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Min"
                                                    value={filters.mileageRange.min}
                                                    onChange={(e) => handleFilterChange('mileageRange', { ...filters.mileageRange, min: e.target.value })}
                                                    className="custom-input"
                                                />
                                                <span className="range-separator">to</span>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Max"
                                                    value={filters.mileageRange.max}
                                                    onChange={(e) => handleFilterChange('mileageRange', { ...filters.mileageRange, max: e.target.value })}
                                                    className="custom-input"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faCog} className="me-2" />
                                                Transmission
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.transmission}
                                                onChange={(e) => handleFilterChange('transmission', e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">All Types</option>
                                                <option value="Automatic">Automatic</option>
                                                <option value="Manual">Manual</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="filter-group">
                                            <Form.Label>
                                                <FontAwesomeIcon icon={faSort} className="me-2" />
                                                Sort Results
                                            </Form.Label>
                                            <Form.Select
                                                value={sortBy}
                                                onChange={(e) => setSortBy(e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="newest">Newest First</option>
                                                <option value="priceAsc">Price: Low to High</option>
                                                <option value="priceDesc">Price: High to Low</option>
                                                <option value="mileageAsc">Mileage: Low to High</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <div className="row g-3">
                    {loading ? (
                        <div className="col-12 text-center p-5">
                            <Spinner animation="border" style={{ color: '#ed1c24' }} />
                        </div>
                    ) : error ? (
                        <div className="col-12">
                            <div className="alert alert-danger">{error}</div>
                        </div>
                    ) : filteredCars.length === 0 ? (
                        <div className="col-12 text-center">
                            <div className="no-results p-5">
                                <FontAwesomeIcon icon={faSearch} className="mb-3" style={{ fontSize: '3rem', color: '#ed1c24' }} />
                                <p className="h4 text-muted">No cars available matching your criteria.</p>
                            </div>
                        </div>
                    ) : (
                        filteredCars.map((car) => (
                            <div className="col-md-4" key={car._id}>
                                <div className="car-card">
                                    <div className="car-image">
                                        <img 
                                            src={car.images && car.images.length > 0 
                                                ? `https://api.aboutcars.pk${car.images[0]}`
                                                : '/placeholder-car.jpg'} 
                                            alt={`${car.make} ${car.model}`}
                                            className="car-main-image"
                                        />
                                    </div>
                                    <div className="car-content">
                                        <h3 className="car-title">
                                            <FontAwesomeIcon icon={faCar} className="car-icon" />
                                            {car.year} {car.make} {car.model}
                                        </h3>
                                        <div className="car-specs">
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faPalette} style={{ color: '#ed1c24' }} />
                                                <span>Color: {capitalizeFirst(car.color)}</span>
                                            </div>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faTachometerAlt} style={{ color: '#ed1c24' }} />
                                                <span>Mileage: {car.mileage ? car.mileage.toLocaleString() : '0'} km</span>
                                            </div>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faCog} style={{ color: '#ed1c24' }} />
                                                <span>Transmission: {capitalizeFirst(car.transmission)}</span>
                                            </div>
                                            <div className="spec-item">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#ed1c24' }} />
                                                <span>Reg City: {capitalizeFirst(car.registration_city)}</span>
                                            </div>
                                        </div>
                                        <div className="car-price">
                                            <span className="price-amount">PKR {car.price ? car.price.toLocaleString() : '0'}</span>
                                        </div>
                                        <Link to={`/car/${car._id}`} className="view-details-btn">
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <style jsx>{`
                        .stock-page {
                            background-color: #f4f6f8;
                            min-height: 100vh;
                    padding: 0;
                    animation: slideDown 0.5s ease;
                }

                @keyframes slideDown {
                    from {
                        opacity: 0;
                        transform: translateY(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                        }

                        .page-header {
                    background: #ed1c24;
                    padding: 1rem;
                    margin-bottom: 1rem;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                        }

                        .page-header h2 {
                            color: white;
                            margin: 0;
                            font-weight: 600;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }

                        .back-section {
                            text-align: left;
                            margin-bottom: 0.5rem;
                        }

                        .back-link {
                            color: white;
                            text-decoration: none;
                            font-weight: 600;
                            display: inline-flex;
                            align-items: center;
                            gap: 0.5rem;
                            font-size: 1rem;
                            transition: all 0.3s ease;
                        }

                        .back-link:hover {
                            color: rgba(255, 255, 255, 0.8);
                            transform: translateX(-5px);
                        }

                        .filter-card {
                            background-color: white;
                            border: none;
                    border-radius: 0.5rem;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    margin-bottom: 2rem;
                    animation: slideIn 0.5s ease;
                }

                @keyframes slideIn {
                    from {
                        opacity: 0;
                        transform: translateX(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
                        }

                        .filter-header {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                    margin-bottom: 1rem;
                    padding-bottom: 0.75rem;
                    border-bottom: 1px solid #eee;
                        }

                        .filter-title {
                            display: flex;
                            align-items: center;
                    gap: 0.75rem;
                        }

                        .filter-title h3 {
                            margin: 0;
                    font-size: 1.2rem;
                            font-weight: 600;
                    color: #ed1c24;
                        }

                        .filter-icon {
                    font-size: 1.2rem;
                    color: #ed1c24;
                        }

                        .filter-sections {
                            display: flex;
                            flex-direction: column;
                    gap: 1rem;
                        }

                        .filter-section {
                            background-color: #f8f9fa;
                    padding: 1.25rem;
                            border-radius: 0.5rem;
                    border: 1px solid #eee;
                    transition: all 0.3s ease;
                }

                .filter-section:hover {
                    box-shadow: 0 4px 12px rgba(237, 28, 36, 0.1);
                    transform: translateY(-2px);
                        }

                        .section-title {
                            font-size: 1.1rem;
                            font-weight: 600;
                    color: #ed1c24;
                    margin-bottom: 1.25rem;
                            padding-bottom: 0.5rem;
                    border-bottom: 1px solid rgba(237, 28, 36, 0.2);
                        }

                        .filter-group {
                    margin-bottom: 0.75rem;
                    animation: fadeIn 0.5s ease;
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                        }

                        .form-label {
                    font-size: 0.95rem;
                            font-weight: 600;
                    color: #ed1c24;
                    margin-bottom: 0.35rem;
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                        }

                        .form-label svg {
                    color: #ed1c24;
                    font-size: 1rem;
                        }

                        .custom-select, .custom-input {
                    font-size: 0.95rem;
                    padding: 0.5rem 0.75rem;
                            border: 1px solid #dee2e6;
                    border-radius: 0.4rem;
                            transition: all 0.3s ease;
                }

                .custom-select:hover, .custom-input:hover {
                    border-color: #ed1c24;
                        }

                        .custom-select:focus, .custom-input:focus {
                    border-color: #ed1c24;
                    box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25);
                        }

                        .range-inputs {
                            display: flex;
                            align-items: center;
                    gap: 0.75rem;
                        }

                        .range-separator {
                    font-size: 0.95rem;
                    color: #ed1c24;
                            font-weight: 500;
                        }

                        .reset-btn {
                    font-size: 0.95rem;
                    padding: 0.4rem 1rem;
                    border: 1px solid #ed1c24;
                            transition: all 0.3s ease;
                        }

                        .reset-btn:hover {
                    background-color: #ed1c24;
                            color: white;
                    transform: translateY(-2px);
                        }

                        @media (max-width: 768px) {
                    .container-fluid {
                        padding-left: 0.75rem;
                        padding-right: 0.75rem;
                    }
                }

                .car-card {
                    background: white;
                    border-radius: 8px;
                    overflow: hidden;
                    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                    transition: transform 0.3s ease;
                    height: 100%;
                    margin: 0;
                }

                .car-image {
                    position: relative;
                    height: 200px;
                    overflow: hidden;
                }

                .car-main-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease;
                }

                .car-content {
                    padding: 1.25rem;
                }

                .car-title {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: #333;
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;
                                gap: 0.5rem;
                            }

                .car-icon {
                    color: #ed1c24;
                }

                .car-specs {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    margin-bottom: 1rem;
                }

                .spec-item {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: #666;
                    font-size: 0.9rem;
                }

                .spec-item svg {
                    width: 16px;
                }

                .car-price {
                    margin: 1rem 0;
                    text-align: left;
                }

                .price-amount {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: #333;
                }

                .view-details-btn {
                    display: inline-block;
                    width: 100%;
                    padding: 0.75rem;
                    background: #ed1c24;
                    color: white;
                    text-align: center;
                    border-radius: 25px;
                    text-decoration: none;
                    font-weight: 600;
                    transition: all 0.3s ease;
                }

                .view-details-btn:hover {
                    background: #d91414;
                    color: white;
                    transform: translateY(-2px);
                }

                @media (max-width: 768px) {
                    .car-image {
                        height: 180px;
                    }
                    
                    .car-content {
                        padding: 1rem;
                    }
                    
                    .car-title {
                        font-size: 1.1rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default Stock;
