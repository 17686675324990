// Ensure API URL is properly formatted
const API_URL = process.env.REACT_APP_API_URL 
    ? process.env.REACT_APP_API_URL.endsWith('/') 
        ? process.env.REACT_APP_API_URL.slice(0, -1)
        : process.env.REACT_APP_API_URL
    : 'https://api.aboutcars.pk';

export const API_ENDPOINT = `https://api.aboutcars.pk/api`;

// Helper function to validate and format API URLs
export const getValidApiUrl = (endpoint) => {
    try {
        if (!endpoint) {
            throw new Error('Endpoint is required');
        }

        // Remove leading slash if present
        const cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;
        
        // Construct the full URL
        const fullUrl = `${API_ENDPOINT}/${cleanEndpoint}`;
        
        // Validate the URL
        new URL(fullUrl);
        
        return fullUrl;
    } catch (error) {
        console.error('Invalid API URL:', error);
        return null;
    }
}; 