import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "../sell/sell_style.css";
import axios from "axios";
import { useGlobalContext } from "../../context/DataContext";
import { useEffect } from "react";
import { API_ENDPOINT, getValidApiUrl } from '../../config/config';
import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" className="flex-column progress_col">
      <h1 className="text-center">We are uploading your data, stay with us!</h1>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          className="progress_bar_width"
          {...props}
          style={{
            width: "60vw",
            height: "30px",
            background: "#ffe8e9",
            borderRadius: "5px",
            marginTop: "15px",
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            fontSize: "24px",
            textAlign: "center",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
  },
});



const SellSubmit = (props) => {
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const { setHandleNext, setHandleNext2, setDivHandle, divHandle } =
    useGlobalContext();

  const navigate = useNavigate();

  const { data } = props;

  const handleBack = () => {
    setDivHandle(false);
    setHandleNext(true);
    setHandleNext2(true);
    // console.log(divHandle);
  };
  const [uploaded, setUploaded] = useState(false);
  const handleimageupload = async () => {
    let i = 0;
    let uploadedImages = [];
    setOpen(true);

    try {
        if (!data || !data.images) {
            throw new Error('No image data available');
        }
        
        if (!Array.isArray(data.images) || data.images.length === 0) {
            throw new Error('Please select at least one image to upload');
        }

        data.images.forEach((file, index) => {
            if (!(file instanceof File)) {
                throw new Error(`Invalid file format for image ${index + 1}`);
            }
        });

        const uploadPromises = data.images.map(async (file, index) => {
            const formData = new FormData();
            formData.append("carImages", file);
            
            const url = getValidApiUrl('cars/upload-image');
            if (!url) {
                throw new Error('Server configuration error: Invalid API URL');
            }

            try {
                console.log(`Attempting to upload image ${index + 1} to ${url}`);
                console.log('File being uploaded:', {
                    name: file.name,
                    type: file.type,
                    size: file.size
                });

            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                    },
                    timeout: process.env.REACT_APP_API_TIMEOUT || 30000,
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress((prevProgress) => {
                            const totalProgress = (index * 100 + percentCompleted) / data.images.length;
                            return Math.min(totalProgress, 99);
                        });
                    }
                });

                console.log(`Response for image ${index + 1}:`, response.data);

                if (!response || !response.data) {
                    throw new Error(`No response received for image ${index + 1}`);
                }

                if (response.data.Status === "Success" && response.data.data) {
                    i++;
                    console.log(`Successfully uploaded image ${index + 1}`);
                return response.data.data;
                }
                
                throw new Error(response.data.message || `Upload failed for image ${index + 1}: No data returned`);
            } catch (error) {
                console.error(`Error uploading image ${index + 1}:`, {
                    error: error,
                    file: file.name,
                    response: error.response?.data
                });
                
                if (error.code === 'ECONNABORTED') {
                    throw new Error(`Upload timeout for image ${index + 1} - Please check your connection`);
                }
                if (error.response) {
                    const errorMessage = error.response.data?.message || error.response.data?.Message || error.response.statusText || 'Unknown error';
                    throw new Error(`Server error for image ${index + 1}: ${errorMessage}`);
                }
                if (error.code === 'ERR_NETWORK') {
                    throw new Error('Cannot connect to server. Please verify that:\n1. The server is running\n2. You are connected to the internet\n3. The server URL is correct');
                }
                throw new Error(`Upload failed for image ${index + 1}: ${error.message || 'Unknown error'}`);
            }
        });

        try {
            console.log('Starting upload of all images...');
        const results = await Promise.all(uploadPromises);
        uploadedImages = results.filter(Boolean);
            
            console.log(`Uploaded ${uploadedImages.length} of ${data.images.length} images`);
        
        if (uploadedImages.length === data.images.length) {
                setProgress(100);
            await onSubmit(uploadedImages);
        } else {
                throw new Error(`Only ${uploadedImages.length} of ${data.images.length} images were uploaded successfully`);
            }
        } catch (error) {
            throw new Error(`Upload process failed: ${error.message}`);
        }
    } catch (error) {
        console.error('Error details:', {
            message: error.message,
            code: error.code,
            response: error.response,
            stack: error.stack
        });
        
        setOpen(false);
        setProgress(0);
        alert(error.message || 'An unexpected error occurred while uploading images. Please try again.');
    }
  };

  const onSubmit = async (uploadedImages) => {
    if (data.selectExt === "" || data.selectOption === null) {
      return alert("Please fill all the fields");
    }

    try {
        const submissionData = {
            make: data.selectCar,
            registerCity: data.selectOption,
            exteriorColor: data.selectExt,
            transmission: data.selectTrans,
            milage: data.mileage,
            priority: data.selectPriority,
            availability: data.selectAvailable,
            name: data.contact.name,
            availableDays: data.availableDays || [],
            email: data.contact.email,
            contactNo: data.contact.number,
            city: data.contact.city,
            address: data.contact.address,
            notes: data.contact.notes || "",
            carImages: uploadedImages
        };

        console.log('Preparing submission data:', submissionData);

        const url = getValidApiUrl('cars/add-sell');
        if (!url) {
            throw new Error('Invalid submission URL configuration');
        }

        console.log('Submitting to URL:', url);

        const response = await axios.post(url, {
            data: JSON.stringify(submissionData)
        }, {
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: process.env.REACT_APP_API_TIMEOUT || 30000
        });

        console.log('Server response:', response.data);

        if (response.data.Status === "Success") {
            setProgress(100);
            navigate("/successfull");
        } else {
            throw new Error(response.data.Message || response.data.message || 'Submission failed');
        }
    } catch (error) {
        console.error('Error submitting form:', {
            error: error,
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });

        let errorMessage = 'Error submitting form. ';
        if (error.response) {
            errorMessage += error.response.data?.Message || error.response.data?.message || error.message;
        } else if (error.code === 'ECONNABORTED') {
            errorMessage += 'Request timed out. Please try again.';
        } else if (error.code === 'ERR_NETWORK') {
            errorMessage += 'Network error. Please check your connection.';
        } else {
            errorMessage += error.message;
        }

        setOpen(false);
        alert(errorMessage);
        throw error;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  

  return (
    <div>
      <div className="review-container">
        <section className="buySubmit">
          <div className="title-text">
            <h1>Review Your Request</h1>
            <p className="subtitle">Please review your car selling requirements before submitting</p>
          </div>
          <div className="review-content">
            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">1</div>
                <h2>Car Information</h2>
              </div>
              <div className="info-grid">
                <div className="info-item">
                  <h4>Make & Model</h4>
                  <p className="text-capitalize">
                    {data.selectCar.year} {data.selectCar.make} {data.selectCar.model} {data.selectCar.version}
                  </p>
                </div>
                <div className="info-item">
                  <h4>Registration City</h4>
                  <p className="text-capitalize">{data.selectOption}</p>
                </div>
                <div className="info-item">
                  <h4>Exterior Color</h4>
                  <p>{data.selectExt}</p>
                </div>
                <div className="info-item">
                  <h4>Transmission</h4>
                  <p className="text-capitalize">{data.selectTrans}</p>
                </div>
                <div className="info-item">
                  <h4>Mileage</h4>
                  <p>{data.mileage} km</p>
                </div>
              </div>
            </div>

            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">2</div>
                <h2>Priority & Availability</h2>
              </div>
              <div className="info-grid">
                <div className="info-item">
                  <h4>Priority</h4>
                  <p>{data.selectPriority}</p>
                </div>
                <div className="info-item">
                  <h4>Availability</h4>
                  <p>{data.selectAvailable}</p>
                </div>
                {data.selectAvailable === "Use my location : Use my location for the offer" && (
                  <>
                    <div className="info-item">
                      <h4>Available Time</h4>
                      {data.availableDays.map((item, index) => (
                        <p key={index}>{item.timeField.from} - {item.timeField.to}</p>
                      ))}
              </div>
                    <div className="info-item">
                      <h4>Available Days</h4>
                      {data.availableDays.map((item, index) => (
                        <p key={index}>
                          {item.days.join(", ")}
                        </p>
                      ))}
                </div>
                  </>
                )}
              </div>
            </div>

            <div className="review-section">
              <div className="section-header">
                <div className="step-circle">3</div>
                <h2>Contact Information</h2>
              </div>
              <div className="info-grid">
                <div className="info-item">
                    <h4>Name</h4>
                    <p className="text-capitalize">{data.contact.name}</p>
                </div>
                <div className="info-item">
                    <h4>Email</h4>
                  <p>{data.contact.email || "Not provided"}</p>
                </div>
                <div className="info-item">
                    <h4>Contact Number</h4>
                    <p>{data.contact.number}</p>
                </div>
                <div className="info-item">
                    <h4>City</h4>
                    <p className="text-capitalize">{data.contact.city}</p>
                </div>
                <div className="info-item full-width">
                    <h4>Address</h4>
                    <p>{data.contact.address}</p>
                </div>
                {data.contact.notes && (
                  <div className="info-item full-width">
                    <h4>Additional Notes</h4>
                    <p>{data.contact.notes}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="terms-section">
              <p className="terms-text">
                Kindly review your request, as you won't be able to make changes once you submit.
                <br /><br />
                We're excited you're here and it would be an honour to serve you. Please note that company reserves the right to charge services i.e. 1% of the deal value.
                  <br />
                By clicking Submit Request, you agree to comply with and be bound by these <a href="/" className="terms-link">terms</a>, as applicable to you.
              </p>
            </div>

            <div className="action-buttons">
              <button className="back-button" onClick={handleBack}>
                Edit Information
              </button>
              <button className="submit-button" onClick={handleimageupload}>
                Submit Request
              </button>
            </div>
          </div>
        </section>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.root}>
            <LinearProgressWithLabel value={progress} />
          </div>
        </Modal>
    </div>
  );
};

export default SellSubmit;

