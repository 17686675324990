import React, { useContext } from 'react'
import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Successfull from './components/sell/Succesfull';
import SavedRequest from './components/dashboard/dashboard_other/SavedRequest';
import EditAccount from './components/Admin/EditAccount';
import UsersArea from './components/Admin/UsersArea';
import ClosedRequest from './components/dashboard/dashboard_other/ClosedRequest';
import SalesDetails from './components/dashboard/salesdashboard/SalesDetails';
import ViewDetails from './components/dashboard/salesdashboard/ViewDetails';
import Stock from './components/sell/Stock';
import BuyDetail from './components/dashboard/buydashboard/BuyDetail';
import Login from './components/Admin/Login';
import ErrorPage from './pages/ErrorPage';
import AdminRegister from './components/Admin/AdminRegister';
import UserLogin from './components/Users/UserLogin';
import SellCar from './components/sell/SellCar';
import LoadingComp from './components/Other/LoadingComp';
import { useGlobalContext } from './context/DataContext';
import SellSubmit from './components/sell/SellSubmit';
import ProgressAni from './components/sell/ProgressAni';
import BuyCar from './components/buy/BuyCar';
import Home from './components/Home';
import BuySubmit from './components/buy/BuySubmit';
import AddCar from './components/Admin/AddCar';
import ProtectedRoute from './components/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CarDetails from './components/sell/CarDetails';


// const Home = React.lazy(() => import('./components/Home'));
// const SellCar = React.lazy(() => import('./components/sell/SellCar'));
const AdminDashboard = React.lazy(() => import('./components/Admin/AdminDashboard'));
// const BuyCar = React.lazy(() => import('./components/buy/BuyCar')); 
const AboutCarsDashboard = React.lazy(() => import('./components/dashboard/AboutCarsDashboard'));
// const BuySubmit = React.lazy(() => import('./components/buy/BuySubmit'));
const BottomToTopIcon = React.lazy(() => import('./components/Other/BottomToTopIcon'));


function App(props) {

  const [loading, setLoading] = React.useState(true);

   const {openWait} = useGlobalContext();


  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      try {
        const userData = JSON.parse(userInfo);
        setUser(userData.data.role);
        console.log("User role set from localStorage:", userData.data.role);
      } catch (error) {
        console.error("Error parsing user data:", error);
        localStorage.removeItem('user');
      }
    }
  }, []);

  // Function to check if user is authenticated
  const isAuthenticated = () => {
    const userInfo = localStorage.getItem('user');
    return !!userInfo;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        loading ? <div className="loader-container">
          <div className="loading"><h1>About Cars</h1></div>
        </div> : <> 
        <Suspense fallback={<div className="loader-container">
          <div className="loading"><h1>About Cars</h1></div>
        </div>}>
        <LoadingComp open={openWait} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sell" element={<SellCar />} />
            <Route path="/buy" element={<BuyCar />} />
            <Route path="/sellsubmit" element={<SellSubmit />} />
            <Route path="/buysubmit" element={<BuySubmit />} />
            <Route path="/successfull" element={<Successfull />} />
            <Route path="/carsdashboard" element={<ProtectedRoute><AboutCarsDashboard /></ProtectedRoute>} />
            <Route path="/edit-account" element={<ProtectedRoute><EditAccount /></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute><UsersArea /></ProtectedRoute>} />
            <Route path="/saledetail/:id" element={<ProtectedRoute><SalesDetails /></ProtectedRoute>} />
            <Route path="/viewdetail/:id" element={<ProtectedRoute><ViewDetails /></ProtectedRoute>} />
            <Route path="/stock" element={<Stock/>} />
            <Route path="/car/:id" element={<CarDetails />} />
            <Route path="/buydetail/:id" element={<ProtectedRoute><BuyDetail /></ProtectedRoute>} />
            
            <Route path="/adminlogin" element={<Login />} />
            <Route path="/adminregister" element={<AdminRegister />} />
            <Route path="/userlogin" element={<UserLogin />} />
            <Route path="/admindashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
            <Route path="/add-car" element={<ProtectedRoute><AddCar /></ProtectedRoute>} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/loading" element={<LoadingComp />} />
            <Route path="/progress" element={<ProgressAni />} />

          </Routes>
        </Suspense>
          <BottomToTopIcon />
        </>
      }
    </>
  );
}

export default App;
